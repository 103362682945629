//
// Drives the display of the header on the CMS-based
// top nav implementation.
//

@import 'global/z-indexes';

// Sizes for demo only. Need to make configurable based on designs
$topnav-header-bg: $topnav-bg !default;

// TopNav Header
// Styles the header background.

@mixin topnav-header {
	.topnav {
		&-header {
			@include z-index(topnav);
			background: $topnav-header-bg;
			display: flex;
			flex-wrap: wrap;
			position: relative;
		}
	}
}

