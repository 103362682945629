//
// Drives the display of the dropdown on small and up screens on the CMS-based
// top nav implementation.
//

$topnav-dropdown-bg-color: $white !default;
$topnav-dropdown-close-color: get-color(primary) !default;
$topnav-dropdown-close-font-size: 19px !default;
$topnav-dropdown-close-height: null !default;
$topnav-dropdown-close-right__medium: 0 !default;
$topnav-dropdown-close-top__medium: 0 !default;
$topnav-dropdown-close-right__large: null !default;
$topnav-dropdown-close-top__large: null !default;
$topnav-dropdown-close-width: null !default;
$topnav-dropdown-padding: 20px !default; //place before $topnav-dropdown-close-padding__medium for dependency
$topnav-dropdown-padding__large: null !default;
$topnav-dropdown-promo-padding-bottom: null !default;
$topnav-dropdown-promo-padding-top: null !default;
$topnav-dropdown-promo-padding-bottom__large: null !default;
$topnav-dropdown-promo-padding-top__large: 0 !default;
$topnav-dropdown-close-padding__medium: $topnav-dropdown-padding !default;
$topnav-dropdown-close-padding__large: null !default;
$topnav-dropdown-item-color: get-color(primary) !default;
$topnav-dropdown-item-color-active: $topnav-dropdown-item-color !default;
$topnav-dropdown-l1-active-bg: $topnav-accordion--color__medium !default;
$topnav-dropdown-item-color-active__medium: null !default;
$topnav-dropdown-l1-active-bg__medium: null !default;
$topnav-dropdown-l3-link-color: null !default; // will default to $topnav-dropdown-item-color if null
$topnav-dropdown-l3-link-hover-color: null !default;
$topnav-dropdown-transition-duration: 0.3s !default;
$topnav-dropdown--border: null !default;
$topnav-dropdown-hitarea-enabled: true !default;
$topnav-dropdown-hitarea-height: $topnav-button-height !default;
$topnav-dropdown-hitarea-width: $topnav-button-height * 4 !default;
$topnav-dropdown-close-btn-display: null !default;
$topnav-dropdown-wrapper-background: null !default;
$topnav-dropdown-wrapper-menu-row-padding-right: null !default;
$topnav-dropdown-display: flex !default;
$topnav-dropdown-width-medium: 100% !default;
$topnav-dropdown--overflow: hidden !default;
$topnav-dropdown-close-btn-display-tablet: null !default;

@import 'util/common-mixins';
@import 'global/z-indexes';

//
// Override the width of column entries to support different widths
// like a 5-across layout without sacrificial columns on either side
// to get to the 12 column total
// @param $columnCount Number of columns the column would normally take up (this will be used in the output CSS class name)
// @param $columnTotal Total number of columns this grid is divided by (instead of the normal 12)
// @param $breakpoint The name of the breakpoint to apply this size to (mobile-first means breakpoint-and-up)
//
@mixin topnav-dropdown-grid($columnCount: 1, $columnTotal: $total-columns, $breakpoint: small) {
  $columnSize: $grid-column-count / ($columnTotal/$columnCount);

  @include breakpoint($breakpoint) {
	.#{$breakpoint}#{-$columnCount} {
		@include grid-column-f4($columns: $columnSize, $collapse: false);
	}
  }
}

// TopNav DropDown

@mixin topnav-dropdown {
	.topnav {
		&-accordion {
			&-item {
				&.l1 {
					&.has-children {
						@include topnav-dropdown-parent;
					}

					&.active {
						@include topnav-dropdown-active-parent;


						@include breakpoint(medium) {
							> .topnav-accordion-item-link,
							> .topnav-mainnav-more-label {
								@include topnav-dropdown-active-link;
							}
						}
					}
				}

				&.l3 {
					@include topnav-dropdown-l3;
				}
			}
		}

		&-dropdown {
			display: inline;
			position: static;
			background: $topnav-dropdown-wrapper-background;

			@include breakpoint(medium) {
				background: $topnav-dropdown-bg-color;
				border-bottom: $topnav-dropdown--border;
				display: none;
				height: auto;
				left: 0;
				overflow: hidden;
				padding: 0;
				position: absolute;
				transition: height $topnav-dropdown-transition-duration ease-out;
				width: 100%;
				z-index: z-index(topnav) + 1;

				.columns {
					min-height: 1px; // this prevents empty columns from collapsing to retain layout
				}

				@media only screen and (min-width: 640px) and (max-width: 1024px) {
					overflow: $topnav-dropdown--overflow;
					@media (-webkit-min-device-pixel-ratio: 2) {
						.columns {
							width: $topnav-dropdown-width-medium;
						}					
					}
				}

				.l1.active & {
					@include topnav-active-dropdown;
				}
			}

			&-wrapper {
				@include topnav-dropdown-wrapper;
			}

			.topnav-accordion-item-link,
			.topnav-promo-text-link,
			.topnav-accordion-shopall-link {
				@include topnav-dropdown-link;
			}

			&-close {
				@include topnav-dropdown-close;
				display: $topnav-dropdown-close-btn-display;
				@media only screen and (min-width: 640px) and (max-width: 1024px) {
					@media (-webkit-min-device-pixel-ratio: 2) {
						display: $topnav-dropdown-close-btn-display-tablet;
					}
				}
			}


			// Custom grid layout on medium/large screens when 5 across
			// to avoid sacrificial columns
			&-5column {
				@include topnav-dropdown-grid(2, 10, medium);
				@include topnav-dropdown-grid(2, 10, large);
			}

			&-promo {
				@include topnav-dropdown-column-promo;
			}


			// On large screens, adjust the width of the dropdown wrapper so it
			// remains centered and the column widths match those of a 5-col row
			@include breakpoint(large) {
				@for $i from 2 through 4 {
					&-#{$i}column {
						.topnav-dropdown-wrapper {
							@include topnav-dropdown-wrapper-center($i);
						}
					}
				}
			}
		}
	}
}

@mixin topnav-dropdown-l3 {
	@include breakpoint(medium) {
		> .topnav-accordion-item-link {
			color: $topnav-dropdown-l3-link-color;

			&:active,
			&:focus,
			&:hover {
				color: $topnav-dropdown-l3-link-hover-color;
			}
		}
	}
}

@mixin topnav-active-dropdown {
	@include breakpoint(medium) {
		display: block;
	}
}

@mixin topnav-dropdown-wrapper {
	@include breakpoint(medium) {
		padding: $topnav-dropdown-padding;

		> .row {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding-right: $topnav-dropdown-wrapper-menu-row-padding-right;

			@media only screen and (min-width: 640px) and (max-width: 1024px) {
				@media (-webkit-min-device-pixel-ratio: 2) {
					display: $topnav-dropdown-display;
				}
			}
			&:before,
			&:after {
				display: none; // prevents flex columns from improperly wrapping
			}
		}
	}

	@include breakpoint(large) {
		@if ($topnav-dropdown-padding__large) {
			padding: $topnav-dropdown-padding__large;
		}
	}
}

// adjust the width of the dropdown wrapper so that it's centered
// and the width of the columns within match that of a 5-col row
// $columnCount set to 5 so the default width would result in 100%
@mixin topnav-dropdown-wrapper-center($columnCount: 5) {
	$maxCols: 5;
	$colWidth: 100% / $maxCols;

	> .topnav-accordion-shopall,
	> .row {
		width: $colWidth * $columnCount;
	}
}

@mixin topnav-dropdown-link {
	@include breakpoint(medium) {
		color: $topnav-dropdown-item-color;

		&:active,
		&:focus,
		&:hover {
			color: $topnav-dropdown-item-color-active;
		}
	}
}

@mixin topnav-dropdown-active-link {
	color: $topnav-dropdown-item-color-active;
	background-color: $topnav-dropdown-l1-active-bg;

	@include breakpoint(medium) {
		color: $topnav-dropdown-item-color-active__medium;
		background-color: $topnav-dropdown-l1-active-bg__medium;
	}
}

@mixin topnav-dropdown-parent {
	@include breakpoint(medium) {
		position: static;

		.topnav-mainnav-no-js & {
			&:active,
			&:focus,
			&:hover {

				> a {
					@include topnav-dropdown-active-link;
				}

				.topnav-dropdown {
					@include topnav-active-dropdown;
				}
			}
		}
	}
}

@mixin topnav-dropdown-active-parent {
	@include breakpoint(medium) {
		background-color: $topnav-dropdown-l1-active-bg;

		// add a hidden hit area to the left of the first L1 to prevent the dropdown from collapsing inappropriately
		// when the mouse moves from the L1 to the far-left content inside the dropdown.
		// hit area only gets added when the L1 is active to prevent blocking the logo when dropdown is not active
		@if($topnav-dropdown-hitarea-enabled) {
			&:first-of-type {
				&::before {
					border-bottom: $topnav-dropdown-hitarea-height solid transparent;
					border-left: $topnav-dropdown-hitarea-width solid transparent;
					clip-path: polygon(100% 0, 0% 100%, 100% 100%);
					content: '';
					height: 0;
					position: absolute;
					transform: translateX(-100%);
					width: 0;
				}
			}
		}
	}
}

@mixin topnav-dropdown-close(
	$pad__medium: $topnav-dropdown-close-padding__medium,
	$pad__large: $topnav-dropdown-close-padding__large,
	$top_medium: $topnav-dropdown-close-top__medium,
	$top_large: $topnav-dropdown-close-top__large,
	$right_medium: $topnav-dropdown-close-right__medium,
	$right_large: $topnav-dropdown-close-right__large
) {
	@include breakpoint(medium) {
		@include z-index(topnav);
		background: none;
		border: 0;
		font-size: $topnav-dropdown-close-font-size;
		height: $topnav-dropdown-close-height;
		padding: $pad__medium;
		position: absolute;
		right: $right_medium;
		top: $top_medium;
		width: $topnav-dropdown-close-width;

		&:hover,
		&:focus {
			background: none;
			border: 0;
		}

		span {
			color: $topnav-dropdown-close-color;
		}
	}

	@include breakpoint(large) {
		padding: $pad__large;
		right: $right_large;
		top: $top_large;
	}
}

@mixin topnav-dropdown-column-promo {

	.topnav-column-promo {
		&-media,
		&-text {
			@include grid-column(12);
		}

		a {
			display: inline-block;
		}

		.media {
			overflow: inherit;
		}
	}


	// on medium views, the promo wraps below the other columns so spacing on the dropdown and promo-column may need to be adjusted
	@include breakpoint(medium) {

		.topnav-dropdown-wrapper {
			padding-bottom: $topnav-dropdown-promo-padding-bottom;
		}

		.topnav-column-promo {
			padding-top: $topnav-dropdown-promo-padding-top;

			&-media {
				@include grid-column(3);
			}

			&-text {
				@include grid-column(9);
			}

			// TODO VFDP-522 in Foundation 6 this is probably longer needed
			// because breakpoint collapse and uncollapse are provided OOTB
			.row {
				margin-left: 0;
				margin-right: 0;
			}
		}

		&.topnav-dropdown {
			&-2column {
				.topnav-column-promo {
					padding-top: $topnav-dropdown-promo-padding-top__large; // use large here because the promo is positioned like large screens for 2 columns

					&-media,
					&-text {
						@include grid-column(12);
					}
				}
			}
		}
	}

	// on large views this padding can be removed
	@include breakpoint(large) {
		.topnav-dropdown-wrapper {
			padding-bottom: $topnav-dropdown-promo-padding-bottom__large;
		}

		.topnav-column-promo {
			padding-top: $topnav-dropdown-promo-padding-top__large;

			&-media,
			&-text {
				@include grid-column(12);
			}
		}
	}
}

