//
// Core module for the CMS-based top nav
//

$topnav-color: $white !default;
$topnav-bg: $black !default;
$topnav-button-border: null !default;
$topnav-button-color: $topnav-color !default;
$topnav-button-color-hover: $topnav-color !default; // default to $topnav-color for consistently on small screens
$topnav-button-bg: null !default;
$topnav-button-width: 44px !default;
$topnav-button-height: 55px !default;
$topnav-button-font-size: 23px !default;
$topnav-button-active-color: $topnav-bg !default;
$topnav-button-active-bg: $topnav-color !default;
$topnav-skipnav-link-background: $white !default;
$topnav-skipnav-link-color: $black !default;
$topnav-skipnav-link-padding: 10px 15px !default;
$topnav-search-menu-display: null !default;
$topnav-transparent-header-bg: linear-gradient(rgba(0, 0, 0, 1)10%, rgba(0, 0, 0, 0) 100%) !default;
$topnav-transparent-header-color: $white !default;
$topnav-transparent-header-hover-color: $white !default;

@mixin topnav {
	.topnav {
		position: relative;
		width: 100%;
		z-index: z-index(topnav);

		ul {
			@include clearfix;
			margin: 0;

			&.topnav-mainnav {
				@include breakpoint(medium) {
					display: flex;
					flex-wrap: nowrap;
					justify-content: flex-start;
				}
			}
		}

		li {
			list-style: none;
			margin: 0;
		}

		a {
			text-decoration: none;

			@include breakpoint(medium) {
				color: $topnav-color;
			}
		}

		// Skip-nav link - Only visible when focused. 
		// Override default focused link styles for displaying in topnav
		.show-on-focus {
			background: $topnav-skipnav-link-background;
			color: $topnav-skipnav-link-color;
			padding: $topnav-skipnav-link-padding;
			text-align: center;
			z-index: z-index(topnav) + 1;

			&:active,
			&:focus {
				//override foundation's static position
				position: absolute !important; // sass-lint:disable-line no-important
			}

		}
	}
	.transperant-topnav {
		.sticky:not(.sticky-minimized) {
			background: $topnav-transparent-header-bg;

			@include breakpoint(medium) {
				.topnav-utility-item a,
				.root > .topnav-accordion-item > a {
					color: $topnav-transparent-header-color !important; // sass-lint:disable-line no-important

					&:hover {
						color: $topnav-transparent-header-hover-color !important; // sass-lint:disable-line no-important
					}
				}
			}

			.search {
				border-bottom-color: $topnav-transparent-header-color;
			}

			.icon-shopcart:before {
				filter: brightness(1000%);
			}

			.icon-slim-search:before {
				filter: brightness(1000%);
			}

			.topnav-logo > div,
			.topnav-logo svg {
				filter: invert(0.5) brightness(1000%);
			}

			.topnav-search-container,
			.topnav-smallheader-container {
				.topnav-search-button {
					background-color: transparent;
					transition: revert;
				}

				.icon-search:before {
					filter: brightness(1000%);
				}

				.topnav-search-input {
					background: transparent;
					color: $topnav-transparent-header-color;

					&::placeholder {
						color: $topnav-transparent-header-color;
						opacity: 1;
					}
				}
			}
		}

		&:not(.active) .topnav-menubutton span {
			color: $topnav-transparent-header-color;
		}
	}

.topnav {
	&.new-transperant-topnav,
	.new-transperant-topnav & {
		.sticky:not(.sticky-minimized) {

			@include breakpoint(medium) {

				.topnav-logo>div,
				.topnav-logo svg,
				.icon-shopcart:before {
					filter: none !important;
				}
			}
		}
	}
}	
}

//
// Styles for buttons displayed on small screens
// including the hamburger, cart, and others
//
@mixin topnav-button {
	border-left: $topnav-button-border;
	color: $topnav-color;
	display: inline-block;
	float: $global-right;
	font-size: $topnav-button-font-size;
	height: $topnav-button-height;
	line-height: $topnav-button-height;
	position: relative;
	text-align: center;
	width: $topnav-button-width;

	&:focus,
	&:hover {
		color: $topnav-button-color-hover;
	}

	&.active {
		@include topnav-button-active;
	}

	span:first-of-type {
		line-height: inherit;
	}
}

//
// Active or hover state of small screen buttons
//
@mixin topnav-button-active {
	background-color: $topnav-button-active-bg;
	color: $topnav-button-active-color;
	text-decoration: inherit;
}

// TODO: move to _print.scss
@media print {
	.bcFloat, // bold chat CTA
	.topnav-promobar-container,
	.topnav-menubutton,
	.topnav-searchbutton,
    .topnav-smallheader,
	.topnav-utility-small-container {
        display: none;
	}
}
