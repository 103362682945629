//
// Drives the display of the Search button for small screens
// on the CMS-based top nav implementation.
//

$topnav-search-button-active-bg: null !default;

@mixin topnav-searchbutton {
	.topnav {
		&-searchbutton {

			display: $topnav-search-menu-display;
			order: 3;

			.menubuttonleft & {
				margin-right: calc(#{$topnav-button-width + $topnav-cart-gutter-space}); // when menubutton is aligned left, use searchbutton margin to make room for the absolutely positioned shopping cart - including gutter space between button and active state of searchbutton
			}

			.nocart & {
				margin-right: 0;
			}

			a {
				@include topnav-button;
			}

			&.active {
				a {
					@include topnav-button-active;
					background-color: $topnav-search-button-active-bg;
				}
			}
		}
	}
}

