//
// Drives the display of the logo on the CMS-based
// top nav implementation.
//

$topnav-logo-width: 100px !default;
$topnav-logo-height: 100px !default;
$topnav-logo-margin: 0 0 0 15px !default;
$topnav-logo-height__medium: 20px !default;
$topnav-logo-width__medium: 116px !default;
$topnav-logo-top__medium: 65px !default;
$topnav-logo-margin__medium: $topnav-logo-margin !default;
$topnav-logo-height__large: null !default;
$topnav-logo-width__large: null !default;
$topnav-logo-top__large: null !default;
$topnav-logo-margin__large: null !default;
$topnav-logo-max-width: null !default;

// TopNav Logo
// Styles the link with the brand logo
// as a background.
@mixin topnav-logo {
	.topnav {
		&-logo {
			display: block;
			float: left;
			height: $topnav-logo-height;
			margin: $topnav-logo-margin;
			max-width: $topnav-logo-max-width;
			width: $topnav-logo-width;

			// scale the size of the logo to fit the dimension above
			// because some brand's logos have size embedded in the svg.
			// The static-ui esi markup wraps the svg in a div
			> div,
			svg {
				height: 100%;
				width: 100%;
			}

			@include breakpoint(medium) {
				@include topnav-logo-medium;
			}

			@include breakpoint(large) {
				@include topnav-logo-large;
			}

			.centerlogo-small & {
				@include topnav-logo-centered;
			}
		}

		.logo {
			flex-grow: 1;
			order: 1;
		}
	}
}

@mixin topnav-logo-medium {
	height: $topnav-logo-height__medium;
	left: 0;
	margin: $topnav-logo-margin__medium;
	position: relative;
	top: $topnav-logo-top__medium;
	transform: none;
	width: $topnav-logo-width__medium;
}

@mixin topnav-logo-large {
	height: $topnav-logo-height__large;
	margin: $topnav-logo-margin__large;
	top: $topnav-logo-top__large;
	width: $topnav-logo-width__large;
}

@mixin topnav-logo-centered {
	left: 50%;
	margin-left: 0;
	margin-right: 0;
	position: absolute;
	transform: translateX(-50%);

	@include breakpoint(medium) {
	    @include topnav-logo-medium;
	}

	@include breakpoint(large) {
		@include topnav-logo-large;
	}
}
