//
// Drives the utility top
// nav implementation.
//

@import 'global/topnav/utility'; // Get shared component

@mixin topnav-napa-utility-item {
  border: 0;

  a {
    color: $topnav-utility-item-color;
    font-weight: normal;
    text-transform: uppercase;
    font-size: rem-calc(10px);

    &:hover {
      color: $topnav-utility-item-color-hover;
    }
  }
}

@mixin topnav-utility-napapijri-eu {
	.topnav {
		&-utility {
      &-item {
        @include topnav-napa-utility-item;

        &-link {
          .icon-account {
            .vfdp-user-registered & {
              &::before {
                color: get-color(burnt-orange);
              }
            }
          }
        }
      }
    }
  }
}
.topnav {
	&-header:not(.napa-top-nav-new-design){
		.icon-shopcart::before{
			content: $icon-shopcart-old;
		}
		.icon-search::before{
			content: $icon-search-old;
		}
		.icon-menu::before{
			content: $icon-menu-old;
		}
	}
  }

.topnav-utility-item.wishlist {
  display: none;

  &.has-products {
    display: inline-block;
  }
}

.napa-top-nav-new-design {
	.topnav-utility-item.wishlist {
		&.has-products {
			a:before {
				content: "";
				border-left: none;
				height: rem-calc(16);
				position: absolute;
				top: 7%;
				left: 1%;
				@include breakpoint(medium) {
					border-left: rem-calc(1) solid #000;
				}
			}
		}
	}
}

@mixin new-design-topnav-napa-utility-item {
	padding-right: rem-calc(16);
	span.topnav-utility-item--flag-label {
		padding-left: rem-calc(5);
		font-weight: 300;
		font-size: rem-calc(12);
		line-height: rem-calc(16);
		color: get-color(primary);
		@include breakpoint(medium){
			padding-left: 0;
			font-weight: 300;
			line-height: rem-calc(18);
			color: get-color(eclipse);

			.transperant-topnav & {
					color: get-color(secondary);
				}

			&.new-transperant-topnav,
    		.new-transperant-topnav & {
				color: get-color(primary);;
			}
		}
	}
	@include breakpoint(medium){
		padding-left: rem-calc(16);
		padding-top: rem-calc(2);
	}
	a {
		&:after {
			content: "";
			border-right: none;
			height: rem-calc(14);
			position: absolute;
			top: 7%;
			padding-left: rem-calc(16);
			@include breakpoint(medium) {
				border-right: solid rem-calc(1) $black;

				.transperant-topnav & { 
					border-right: solid rem-calc(1) $white;
				}
				.topnav-cart-icon{
					border-right: none;
				}
			}
		}

		span {
			font-family: get-font('AkkuratPro');
			font-style: normal;
			font-weight: 300;
			font-size: rem-calc(14);
			letter-spacing: 0;
		}

		&.topnav-utility-item-link-icon-sign-out {
			&:before {
				content: "";
				border-left: none;
				height: rem-calc(14);
				left: 0;
				padding-right: rem-calc(16);
				position: absolute;
				top: 7%;
				@include breakpoint(medium) {
					border-left: none;

    				.new-transperant-topnav & {
						border-left: solid rem-calc(1) get-color(primary) !important; // sass-lint:disable-line no-important
					}
				}
				@include breakpoint(large) {
					border-left: solid rem-calc(1) $black;
					.transperant-topnav & {
						border-left: solid rem-calc(1) get-color(secondary);
					}
				}
			}

			&:after {
				border-right: none;
			}
		}
	}
	a.topnav-utility-item-link-icon-account::after {
		@include breakpoint(large) {
			border-right: none;
		}
	}

	&.has-products {
		a {
			span {
				&.topnav-cart-icon {
					@include breakpoint(medium) {
						position: absolute;
					}
				}

				&.topnav-cart-qty {
					@include breakpoint(medium) {
						right: rem-calc(9);
    					top: rem-calc(28);
    					background: get-color(burnt-orange);;
    					align-items: center;
    					font-size: rem-calc(8);
    					font-weight: 500;
						font-family: get-font('NeueHelveticaVFDP');
						border: rem-calc(2) solid $white;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						min-width: rem-calc(16);
						min-height: rem-calc(14);	
					}
				}
			}

			&:after {
				@include breakpoint(medium) {
					border-right: none;
				}
			}
		}
	}

	&.my-account {
		a {
			&:after {
				border-right: none;
			}
		}
	}

	&.topnav-cart {
		position: absolute;
		right: rem-calc(0);
		@include breakpoint(medium) {
			height: rem-calc(54);
			top: rem-calc(32);
			padding: 0;
		}

		.topnav-cart-label {
			display: none;
		}

		.icon-shopcart {
			&:before {
				font-size: rem-calc(28);
    		}
		}
	}

	&--flag-image-wrapper {
		margin-right: rem-calc(6);
		position: relative;
		top: rem-calc(-1);
		text-transform: capitalize;
	}
}
.napa-top-nav-new-design {
	.topnav-utility {
		&-item {
			@include new-design-topnav-napa-utility-item;
			.topnav-utility-item-label {
				line-height: rem-calc(18);
				display: inline-block;
				text-transform: capitalize;
				font-size: rem-calc(12);
			}
			.topnav-utility-item-icon {
				line-height: rem-calc(20);
				display: none;
			}

			&-link {
				padding: rem-calc(12) rem-calc(16);
				@include breakpoint(medium) {
					line-height: inherit;
					padding: 0;
				}
			}
		}
		&-cart-container {
			@include breakpoint(medium) {
				order: 2;
				top: rem-calc(32);
				right: 0;
				padding-top: rem-calc(10);
				max-height: rem-calc(27);
				.transperant-topnav & {
					padding-top: 0;
					margin-top: rem-calc(10);
					order: 1;
					top: 0;
					position: relative;
				}
			}

			position: absolute;

			@include breakpoint(large) {
				position: relative;
				top: 0;
				left: 0;
			}
		}
		&-container {
			margin-top: rem-calc(16);
			border-top: rem-calc(1) solid get-color(napa-gainsboro);
			@include breakpoint(medium) {
				padding-top: 0;
				border-top: none;
				margin-top: 0;
			}
		}
	}

	&.sticky-minimized {
		.topnav-utility {
			&-item {
				&.topnav-cart {
					top: rem-calc(32);
					.transperant-topnav & {
						top: rem-calc(14);
					}
				}
			}
			&-cart-container {
				position: static;
				@include breakpoint(medium) {
					order: 3;
				}
			}
		}
	}
}
.transperant-topnav {
	.napa-top-nav-new-design {
		&.sticky:not(.sticky-minimized) {
			.topnav-cart.has-products {
				a {
					.topnav-cart-icon {
						color: $white;
					}
				}
			}
		}
		&.sticky-minimized {
			.topnav-cart.has-products {
				a {
					.topnav-cart-icon {
						color: $black;
					}
				}
			}
		}
	}
}
.topnav {
	&.new-transperant-topnav,
	.new-transperant-topnav & {
		.topnav-cart.has-products {
			a {
				.topnav-cart-icon {
					color: $black;
				}
			}
		}

		.topnav-utility-item:not(:last-child) {
			a {
				&::after {
					border-right: solid rem-calc(1) $black;
				}
			}
		}
	}
}