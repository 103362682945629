//
// Drives the display of the promo bar on the CMS-based
// top nav implementation.
//

@import 'global/topnav/promobar'; // Get shared component

@mixin napa-topnav-promobar-message {

  // Custom anchor styles to override _shared
  p {
    a,
    a:visited,
    a:active {
      font-weight: normal;
    }

    a:hover {
      color: get-color(burnt-orange);
    }
  }
}


@mixin topnav-promobar-napapijri-eu {
  .topnav {
    .napa-top-nav-new-design {
      .topnav-promobar-carousel {
        .cycle-prev, .cycle-next {
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          @include breakpoint(small only) {
            opacity: 1;
          }
        }
		.topnav-promobar-message{
          left: 10%;
          width: 85%;
        }
      }
      .topnav-promobar {
        border: none;
      }
    }  
    &-promobar-container {

      .topnav-promobar {
        border-top: 2px solid $black;
        border-bottom: 1px solid $light-gray;

        .topnav-promobar-message {
          @include napa-topnav-promobar-message;
        }
      }
    }
    .sticky.napa-top-nav-new-design {
      .topnav-promobar-innercontainer {
        @include breakpoint(large) {
          position: relative;
        }
        position: relative;
      }
    }
  }
  .topnav-dropdown-promo .topnav-column-promo a {
    width: 100%;
  }
}
.napa-top-nav-new-design {
  .topnav-promobar-container {
    @include breakpoint(medium) {
      margin-bottom: rem-calc(31);
    }
    @include breakpoint(large) {
      order: 1;
      margin-bottom: 0;
      z-index: z-index(topnav) +1;
    }
    order: 1;

    .promobar {
      .topnav-promobar {
        height: rem-calc(40);
        @include breakpoint(medium) {
          height: rem-calc(26);
        }
        .slide {
          &.cycle-slide {
            display: none !important;
          }
          &.cycle-slide-active {
            display: block !important;
          }
          p {
            @include breakpoint(small only) {
              font-size: rem-calc(12);
            }
            a,
            a:visited,
            a:active {
              font-weight: 500;
            }
            line-height: rem-calc(16);
            span {
              font-family: get-font('AkkuratPro') !important; // sass-lint:disable-line no-important;
            }
          }
        }
      }
    }
  }
}
.sticky-minimized.napa-top-nav-new-design {
  .topnav-promobar-container {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}