//
// Drives the display of the promo bar on the CMS-based
// top nav implementation.
//

@import 'global/topnav/smallheader'; // Get shared component

$topnav-logo-mobile: 0 !default;

.napa-top-nav-new-design {
  .topnav-smallheader-container {

    box-shadow: 0 rem-calc(4) rem-calc(20) 0 rgba($black,0.08);

    @include breakpoint(medium) {
      box-shadow: none;
    }

    @include breakpoint(large) {
      order: 3;
    }
    order: 2;

    .topnav-logo {
      width: rem-calc(125);
      height: rem-calc(34);
      top:  $topnav-logo-mobile;
      margin: rem-calc(17) rem-calc(16);
      @include breakpoint(medium) {
        width: rem-calc(202);
        height: rem-calc(41);
        top: 0;
      }
    }

    .topnav-search-button {
      &.active {
        .icon-search {
          background: none;
        }
      }
    }

    .topnav-searchbutton {
      a {
        width: rem-calc(49);
        font-size: rem-calc(24);
        margin-top: rem-calc(10);
        @include breakpoint(medium) {
          margin-top: 0;
        }
      }
    }
    
    .topnav-smallheader-cart {
      .topnav-cart{
        &.has-products {
          .topnav-cart-qty {
            align-items: center;
            background: get-color(napa-orange);
            font-size: rem-calc(8);
            border: rem-calc(2) solid $white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            line-height: normal;
            padding: 0;
            position: absolute;
            padding-top: rem-calc(1);
            text-align: center;
            margin-right: rem-calc(-17);
            margin-top: rem-calc(-4);
          }
        }
      }  
    }
    .topnav-menubutton {
      .icon-close {
        font-size: rem-calc(20);
      }
    }
  }

  .topnav-promobar .icon-arrow-left-indicator-open {
    &:before {
      color: $white;
      font-size: rem-calc(10);
    }
  }
  
  .topnav-promobar .icon-arrow-right-indicator-open {
    &:before {
      color: $white;
      font-size: rem-calc(10);
    }
  }

  .topnav-accordion-indicator {
    &:before {
      color: $black;
    }
  }

  &.topnav-header {
    &.show-menubutton-text {
      .topnav-menubutton {
        span {
          line-height: rem-calc(16);
          font-weight: 500;
        }
      }
    }
  }
  .topnav-menubutton a {
    margin-top: rem-calc(10);
  }
 /* .topnav-menubutton-text {
    display: none !important;
  }*/
  .topnav-utility-small-container {
    .topnav-utility {
      margin-top: rem-calc(17);
      @include breakpoint(medium) {
        margin-top: 0;
      }
    }
  }
  .icon-close:before {
    content: $icon-close-thin;
  }
}

.topnav {
  .napa-top-nav-new-design {
    &.sticky-minimized {
      .topnav-smallheader-container {
        @include breakpoint(medium) {
          order: 2;
        }
      }
    }
  }
  &.transperant-topnav,
  .transperant-topnav & {
    .napa-top-nav-new-design {
      .topnav-smallheader-container {
        box-shadow: none;
        .topnav-logo {
          width: rem-calc(116);
          height: rem-calc(19);

          @include breakpoint(medium) {
            width: rem-calc(194);
            height: rem-calc(33);
          }
        }
      }
    }
  }

  &.new-transperant-topnav,
    .new-transperant-topnav & {
      .sticky:not(.sticky-minimized) {
        .topnav-smallheader-container {
        background-color: $white;
        }

        .topnav-logo > div,
        .topnav-logo svg {
          filter: none;
        }
      }
    }
}
