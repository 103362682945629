//
// Drives the display of the cart button on the CMS-based
// top nav implementation.
//

@import 'global/topnav/cart';

@mixin topnav-napa-cart {
  color: $black;

  &:hover {
    color: $topnav-utility-item-color-hover;
  }
}

@mixin topnav-napa-cart-icon-active {
  color: get-color(burnt-orange);
}

@mixin topnav-napa-cart-label {
  text-transform: uppercase;
  font-weight: normal;

  @include breakpoint(medium) {
    padding-right: 0;
  }

}

@mixin topnav-napa-cart-qty {
  // Position cart quantity on top of icon for mobile - custom for Napapijri
  color: $white;
  font-size: em-calc(8);
  font-weight: normal;
  margin: -0.8em -1em 0 0;
  position: absolute;
  top: 46px;

  @include breakpoint(medium) {
    color: inherit;
    font-size: $topnav-utility-item--font-size__medium;
    margin: 0;
    min-width: 0;
    position: relative;
    top: 50%;

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }
}

@mixin topnav-utility-item-cart-napapijri-eu {

  .topnav {
    &-cart {
      a {
        @include topnav-napa-cart;
      }

      &.has-products {
        // Icon is orange when cart contains products
        .topnav-cart-icon {
          @include topnav-napa-cart-icon-active;
        }
      }

      .topnav-cart-icon {
        font-size: 18px;

        @include breakpoint(medium) {
          font-size: 13px;
        }
      }

      &-label {
        @include topnav-napa-cart-label;
      }

      &-qty {
        @include topnav-napa-cart-qty;
      }
    }

    &-wishlist {
      &-qty {
        @include topnav-napa-cart-qty;
      }
    }
  }
}
.napa-top-nav-new-design.sticky-minimized {
  .topnav-minicart-panel {
    top: rem-calc(100);

    .transperant-topnav & {
      top: rem-calc(80);
    }
  }
  
}
.napa-top-nav-new-design {
  .topnav-minicart-panel {
    top: rem-calc(128);
    
        .transperant-topnav & {
          top: rem-calc(96);
        }
    }
  .topnav-cart-qty {
    top: rem-calc(45);
  }

   .topnav-cart-icon {
    font-size: rem-calc(24) !important; // sass-lint:disable-line no-important
    }
    .icon-search,.icon-menu   {
      font-size: rem-calc(24); 
      @include breakpoint(medium) {
        font-size: rem-calc(28);
      }
    }
  .topnav-cart.has-products {
    a {
      width: rem-calc(44);
      margin-top: rem-calc(10);
      .topnav-cart-icon {
        color: $black;

        &.new-transperant-topnav,
    		.new-transperant-topnav & {
          color: $black !important; // sass-lint:disable-line no-important
        }
      }
      .topnav-cart-qty {
        &::after , &::before {
          content: none;
        }
        color: $white;
      }
    }
  }
  .topnav-cart:not(.has-products) {
      a {
        padding-right: rem-calc(5);
        margin-top: rem-calc(10);
        @include breakpoint(medium) {
          margin-top: rem-calc(5);
        }
      }

      .topnav {
        &-cart {

          &-qty {
            display: none;
          }
        }
      }
    }
    .topnav-utility-item--flag-link{
      line-height: rem-calc(20);
      padding: rem-calc(8) rem-calc(16);
      @include breakpoint(medium) {
        padding: 0;
      }
    }
}

.transperant-topnav {
  .napa-top-nav-new-design {
    &.sticky:not(.sticky-minimized) {
      .topnav-smallheader-container {
        .topnav-cart-icon, .icon-search, .icon-menu, .topnav-menubutton-text {
          color: get-color(secondary);
        }
      }
    }
  }
}

.topnav {
  &.new-transperant-topnav,
  .new-transperant-topnav & {
    .napa-top-nav-new-design {
      &.sticky:not(.sticky-minimized) {
        .topnav-smallheader-container {

          .topnav-cart-icon,
          .icon-search,
          .icon-menu {
            color: get-color(primary) !important; // sass-lint:disable-line no-important
          }
        }
      }
    }
  }
}