//
// Drives the display of the text-block on the CMS-based
// top nav implementation.
//

$topnav-textblock--indent: $topnav-accordion-item--indent !default;
$topnav-textblock--padding: $topnav-accordion-item--padding-base !default;
$topnav-textblock--link-color: $topnav-dropdown-l3-link-color !default;
$topnav-textblock--link-color-hover: $topnav-dropdown-l3-link-hover-color !default;
$topnav-textblock--link-decoration: null !default;
$topnav-textblock--link-decoration-hover: null !default;

@mixin topnav-textblock {
  @include topnav-accordion-indent(2, $topnav-textblock--indent, $topnav-textblock--padding); //apply the same indent and padding as the accordion L2 links
}

@mixin topnav-textblock-link {
  color: $topnav-textblock--link-color;
  text-decoration: $topnav-textblock--link-decoration;

  &:active,
  &:focus,
  &:hover {
    color: $topnav-textblock--link-color-hover;
    text-decoration: $topnav-textblock--link-decoration-hover;
  }
}

@mixin topnav-dropdown-textblock {
  .topnav {
    &-textblock {
      @include topnav-textblock;

      a {
        @include topnav-textblock-link;
      }
    }
  }
}

