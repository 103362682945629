//
// Drives the flag selector top nav implementation.
//

$topnav-utility-item--padding: 10px !default; // Fallback in case _utility.scss is not loaded before _flag.scss

$topnav-utility-item--flag-image-gutter: $topnav-utility-item--padding/2 !default;
$topnav-utility-item--flag-line-height__medium: $topnav-utility-item-height--medium !default;
$topnav-utility-item--flag-line-height__large: null !default;
$topnav-utility-item--flag-label-padding: rem-calc(2px, $topnav-utility-item--font-size) !default;
$topnav-utility-item--flag-label-top: null !default;
$topnav-utility-item--flag-link-color: null !default;
$topnav-utility-item--flag-link-color-hover: null !default;
$topnav-utility-item--flag-link-padding__medium: 0 !default;
$topnav-utility-item--flag-link-padding__large: null !default;
$topnav-utility-item--flag-width: 17px !default;
$topnav-utility-item--flag-width__medium: 20px !default;
$topnav-utility-item-flag-position: -2px !default;
$topnav-utility-item-flag-top-medium: null !default;
$topnav-utility-item-flag-wrapper-margin-right-medium: 0 !default;
$topnav-utility-item-flag-wrapper-margin-left: null !default;


@mixin topnav-utility-item-flag {
	.topnav-utility-item--flag {
		&-image {
			position: relative;
			top: $topnav-utility-item-flag-position;
			width: $topnav-utility-item--flag-width;

			@include breakpoint(medium) {
				margin-right: $topnav-utility-item--flag-image-gutter;
				top: $topnav-utility-item-flag-top-medium;
				width: $topnav-utility-item--flag-width__medium;
			}

			// Align flag icon consistently with utility nav items
			&-wrapper {
				display: inline-block;
				margin-left: $topnav-utility-item-flag-wrapper-margin-left;
				margin-right: $topnav-utility-item--icon-spacer;
				text-align: center;
				width: $topnav-utility-item--icon-width;

				@include breakpoint(medium) {
					margin-right: $topnav-utility-item-flag-wrapper-margin-right-medium;
					width: auto;
				}
			}
		}

		@include breakpoint(medium) {
			&-link {
				color: $topnav-utility-item--flag-link-color;
				line-height: $topnav-utility-item--flag-line-height__medium;
				padding: $topnav-utility-item--flag-link-padding__medium;

				&:hover,
				&:focus {
					color: $topnav-utility-item--flag-link-color-hover;
				}
			}

			&-label {
				padding-left: $topnav-utility-item--flag-label-padding;
				position: relative;
				top: $topnav-utility-item--flag-label-top;
			}
		}

		@include breakpoint(large) {
			&-link {
				line-height: $topnav-utility-item--flag-line-height__large;
				padding: $topnav-utility-item--flag-link-padding__large;
			}
		}
	}
}
