//
// Drives the display of the sticky nav on the CMS-based
// top nav implementation.
//

@import 'global/topnav/sticky'; // Get shared component


// Override styling of cart in sticky-nav when no items in cart
@mixin topnav-sticky-napapijri-eu {
  .topnav {
    .napa-top-nav-new-design {
      @include breakpoint(medium) {
        box-shadow: 0 0.30769rem 1.53846rem 0 rgba(0, 0, 0, 0.08);
      }
      &.sticky-minimized {
        .topnav-accordion .l1.topnav-accordion-item > a {
          margin-bottom: rem-calc(20);
        }
        .topnav-accordion-item-link, .topnav-mainnav-more-label{
          top: rem-calc(15);
        }
        .topnav-dropdown {
          top: rem-calc(101);
        }
        .topnav-utility-item {
          &:not(.topnav-cart) {
            display: none !important; // sass-lint:disable-line no-important
          }

          a{
            &.topnav-utility-item-link-icon-sign-out {
              &:before {
                @include breakpoint(medium) {
                  border-left: none;
                }
              }
            }
          }
        }
        .topnav-search-container {
          width: rem-calc(191);

          @include breakpoint(medium) {
            padding: rem-calc(14) rem-calc(4);
          }
        }
        .topnav-logo {
          @include breakpoint(medium) {
            height: rem-calc(41);
            width: rem-calc(202);
            margin: rem-calc(17) rem-calc(16);
            top: 0;
          }
        }

        .topnav-cart {
          a {
            &:after {
              @include breakpoint(medium) {
                border-right: none;
              }
            }
          }
        }

        .topnav-accordion {
          @include breakpoint(medium) {
            padding-top: rem-calc(5);
          }
        }
      }
    }

    &.transperant-topnav,
    .transperant-topnav & {
      .napa-top-nav-new-design {
        @include breakpoint(medium) {
          box-shadow: none;
        }
        &.sticky-animation {
          animation: slideDown 1s ease-in-out;
        }

        .topnav-utility-item {
          a:after:not(:last-child) {
            border-right: solid rem-calc(1) get-color(secondary);
          }
        }

        &:not(.active) .topnav-menubutton span {
          color: get-color(primary);
        }
      }
      & > .topnav-promobar-container {
        position: fixed;
        top: 0;

        .topnav-promobar {
          border-bottom: 0;
          height: rem-calc(48);
          min-height: rem-calc(40);
          @include breakpoint(medium) {
            height: rem-calc(30);
            min-height: rem-calc(30);
          }
        }
      }
      .sticky:not(.sticky-minimized) {
        @include breakpoint(medium) {
          background: $topnav-transparent-header-bg;
        }

        @include breakpoint(medium) {
          .topnav-utility-item a,
          .root > .topnav-accordion-item > a {
            color: $topnav-transparent-header-color !important;

            &:hover {
              color: $topnav-transparent-header-hover-color !important;
            }
          }
        }

        .topnav-search-container {
          .topnav-search-button-container {
            @include breakpoint(medium) {
              background-color: inherit;
            }
            .topnav-search-button {
              @include breakpoint(medium) {
                color: get-color(secondary);
                line-height: inherit;
              }
            }
          }
.topnav-search-input-container {
  .topnav-search-input {
    color: $black;
  
    &::placeholder {
      color: $black;
  
      @include breakpoint(medium) {
        color: $white;
      }
    }
  
    @include breakpoint(medium) {
      &:focus {
        border: none !important; // sass-lint:disable-line no-important
  
        &::placeholder {
          color: transparent;
        }
      }
  
      height: auto;
      line-height: inherit;
      color: $white;
    }
  }
}
          .topnav-clear-input {
            color: $black;
            @include breakpoint(medium) {
              color: $white;
            }
            
          }
        }

        .search {
          border-bottom-color: $black;
          @include breakpoint(medium) {
            border-bottom-color: $topnav-transparent-header-color;
          }
         
        }

        .icon-shopcart::before {
          filter: inherit;
        }

        .topnav-logo > div,
        .topnav-logo svg {
          @include breakpoint(medium) {
            filter: invert(0.4) brightness(1000%);
          }
        }

        .topnav-search-container,
        .topnav-smallheader-container {

          .topnav-search-input {

            &::placeholder {
              color: $topnav-transparent-header-color;
              opacity: 1;
            }
          }
        }
      }

      .topnav-cart {
        @include breakpoint(medium) {
          top: rem-calc(34);
        }
      }

      .topnav-utility {
        @include breakpoint(medium) {
          padding: 0;
        }

        &-item {
          @include breakpoint(medium) {
            height: auto;
            padding-top: 0;
          }
          &-link {
            @include breakpoint(medium) {
              line-height: inherit;
            }
          }
        }
      }

      &.active {
        .sticky:not(.sticky-minimized) {
          .topnav-logo > div,
          .topnav-logo svg {
            filter: inherit;
          }
        }
        .napa-top-nav-new-design {
          background: get-color(secondary);
        }
      }
    }

    &.new-transperant-topnav,
    .new-transperant-topnav & {
      .sticky:not(.sticky-minimized) {
        @include breakpoint(medium) {
          background: $white;
        }


        @include breakpoint(medium) {

          .topnav-utility-item a,
          .root>.topnav-accordion-item>a {
            color: $black !important;

            &:hover {
              color: $black !important;
            }
          }
          
          .icon-search:before {
            filter: brightness(0);
          }

          .search {
            border-bottom-color: $black;
          }

          .topnav-utility-item {
            a:after:not(:last-child) {
              border-right: solid rem-calc(1) $black;
            }
          }
        }

        .topnav-search-container {
          .topnav-search-button-container {
            .topnav-search-button {
              color: $black;
            }
          } 
          .topnav-search-input-container {
            .topnav-search-input {
              &::placeholder {
                color: $black;
              }
            }
          }
          
        }
      }
    }

    &.active {
        .topnav-promobar-container {
          z-index: z-index(topnav) + 1;
        }
    }
  }
  @keyframes slideDownMain {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
.aem-new-topnav-design {
  .top-bar-facets.stuck-to-top {
    top: rem-calc(102);
  }
}  