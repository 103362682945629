//
// Drives the display of the menu button on the CMS-based
// top nav implementation.
//

$topnav-menubutton-text-size: 10px !default;
$topnav-menubutton-text-width: 60px !default;

// Styles to adjust the display when menu button text is enabled by authors
@mixin show-menubutton-text {
	.topnav {
		&-menubutton {
			a {
				line-height: inherit;
				width: $topnav-menubutton-text-width;
			}

			span {
				display: block;
				line-height: normal;
			}

			&-group {
				@include vertical-align($position: relative, $offset: 0, $centering: 'center');
			}

			&-text {
				font-size: $topnav-menubutton-text-size;
			}
		}
	}
}

@mixin topnav-button-center-position {
	margin-left: 0;
	order: 0;

	a {
		border-right: $topnav-button-border; // apply border to the right when menubutton is displayed on left (border defaults to null)
	}
}

@mixin topnav-menubutton {
	.topnav {
		&-menubutton {
			margin-left: calc(#{$topnav-button-width + $topnav-cart-gutter-space}); // use menubutton margin to make room for the absolutely positioned shopping cart - including gutter space between button and active state of menubutton
			order: 5;

			.menubuttonleft & {
				@include topnav-button-center-position;
			}

			a {
				@include topnav-button;

				// Extra wrappers because we are targetting the <a>
				// since this button is special and has an active state
				.topnav.active & {
					@include topnav-button-active;
				}
			}
		}

		// remove margin on menubutton when there is no cart
		&-header {
			&.nocart {
				.topnav-menubutton {
					margin-left: $topnav-cart-gutter-space;
				}
			}

			// adjust the menubutton styling when the button's text is enabled
			&.show-menubutton-text {
				@include show-menubutton-text;
			}
		}
	}
}
