//Handles styles for the sticky CMS-based top nav

@import 'global/z-indexes';

// Amount of space to add to the body to clear the sticky nav.
// Set these to reduce a noticeable jump in the page when
// Javascript does a dynamic calculation. Static values
// will always be limited because authors can enable/disable
// the promo bar whenever they want, without code deployments.
// small, medium, large, xlarge
$topnav-sticky-offsets: (60px, 85px, 110px, 135px) !default;
$topnav-sticky-minimized-border: null !default;
$topnav-sticky-minimized-dropdown-border: null !default;
$topnav-sticky-minimized--height__small: 42px !default;
$topnav-sticky-minimized--height__medium: 42px !default;
$topnav-sticky-minimized--line-height__medium: $topnav-sticky-minimized--height__medium !default;
$top-bar-facets-stuck-to-top: $topnav-sticky-minimized--height__medium !default;
$topnav-sticky-minimized-logo: null !default;
$topnav-sticky-minimized-logo_large: null !default;
$topnav-sticky-minimized-logo--height__medium: 20px !default;
$topnav-sticky-minimized-logo--top__medium: 11px !default;
$topnav-sticky-minimized-logo--margin__medium: 0 0 0 10px !default;
$topnav-sticky-minimized-logo--width__medium: 116px !default;
$topnav-sticky-minimized-logo--width__large: null !default;
$topnav-sticky-minimized-logo--margin__large: null !default;
$topnav-sticky-minimized-accordion--margin__medium: null !default;
$topnav-sticky-minimized-l1--font-size__medium: $topnav-accordion--font-size__medium !default;
$topnav-sticky-minimized-l1--line-height__medium: $topnav-accordion-item--line-height__medium !default;
$topnav-sticky-minimized-l1-link--padding__medium: 18px 6px 15px 6px !default;
$topnav-sticky-minimized-l1-active-border-position-bottom__medium: null !default;
$topnav-sticky-minimized-cart--padding__medium: null !default;
$topnav-sticky-minimized-cart-icon-width__medium: null !default;
$topnav-sticky-minimized-header--padding__medium: 0 !default;
$topnav-sticky-minimized-search-container--padding__medium: 6px 4px !default;
$topnav-sticky-minimized-search-container--width__medium: 148px !default;
$topnav-sticky-minimized-search-input-height__medium: null !default;
$topnav-sticky-minimized-cart--height__medium: $topnav-sticky-minimized--height__medium !default;
$topnav-sticky-minimized-cart--line-height__medium: $topnav-sticky-minimized--height__medium !default;
$topnav-sticky-minimized-cart-icon--line-height__medium: $topnav-sticky-minimized--height__medium !default;
$topnav-sticky-minimized-cart--margin__medium: null !default;
$topnav-sticky-minimized-search-input-height__large: null !default;
$topnav-sticky-minimized-flag--padding__medium: 0 8px !default;
$topnav-sticky-minimized-flag--line-height__medium: inherit !default;
$topnav-sticky-minimized--padding__medium: null !default;
$topnav-sticky-minimized-utility-item-link-icon--padding__medium: 0 rem-calc(8px) !default;
$topnav-sticky-minimized-utility-item-link-icon--padding__large: $topnav-sticky-minimized-utility-item-link-icon--padding__medium !default;
$topnav-sticky-minimized-utility-item-link-accessibility--width__medium: 50px !default;
$topnav-sticky-minimized-utility-item--padding__large: 0 !default;
$topnav-sticky-minimized-l1--font-size__large: null !default;
$topnav-sticky-minimized-l1--line-height__large: null !default;
$topnav-sticky-minimized-l1-link--padding__large: 15px 11px !default;
$topnav-sticky-minimized-search-container--padding__large: 6px 10px !default;
$topnav-sticky-minimized-search-container--width__large: 270px !default;
$topnav-sticky-minimized-cart-icon--padding__large: null !default;
$topnav-sticky-minimized-cart-icon-width__large: null !default;
$topnav-sticky-minimized-flag--padding__large: null !default;
$topnav-promobar-innercontainer-position: absolute !default;

//
// Sets the page styles so that the sticky nav
// doesn't mask contents
//
@mixin topnav-sticky-page {
  &.sticky {
    @each $size in $breakpoint-classes {
      $i: index($breakpoint-classes, $size);

      @if $i == 1 {
        &.sticky-#{$size} {
          padding-top: nth($topnav-sticky-offsets, $i);
        }
      } @else {
        @include breakpoint(nth($breakpoint-classes, $i)) {
          @if $i == 2 {
            &.sticky-#{nth($breakpoint-classes, $i - 1)} {
              padding-top: 0;
            }
          }

          &.sticky-#{$size} {
            padding-top: nth($topnav-sticky-offsets, $i);
          }
        }
      }
    }
  }
}

@mixin topnav-sticky-positioning {
	top: 0;
	width: 100%;

	// Wait until javascript loads and adds .sticky to the <body>
	// ensuring the menu doesn't mask page contents during load.
	.sticky & {
		position: fixed;

		@media print {
			position: absolute; // TODO: move to _print.scss
		}
	}
}

$topnav-promobar-innercontainer-position-mobile: absolute !default;

@mixin topnav-sticky {
	.topnav {
		.sticky {
			.topnav-promobar-innercontainer {
				position: $topnav-promobar-innercontainer-position-mobile;
				@include breakpoint(large) {
					position: $topnav-promobar-innercontainer-position;
				}
			}

			@each $size in $breakpoint-classes {
				$i: index($breakpoint-classes, $size);
				@if $i == 1 {
					&.sticky-#{$size} {
						@include topnav-sticky-positioning;
					}
				} @else {
					@include breakpoint(nth($breakpoint-classes, $i - 1)) {
						&.sticky-#{nth($breakpoint-classes, $i - 1)} {
							position: static;
						}

						&.sticky-#{$size} {
							@include topnav-sticky-positioning;
						}
					}
				}
			}

			&-minimized {
				@include topnav-sticky-header-minimized;
			}
		}

		@include topnav-sticky-header-active;
	}

	body {
		@include topnav-sticky-page;
	}
}

//
// Move accordion nav to position relative when open on small screens
// and reflow page contents to be after the menu
//
@mixin topnav-sticky-header-active {
	&.active {
		position: relative;

		.sticky {
			&.sticky-small {
				position: relative;

				.topnav-cart {
					position: fixed;
					top: 0;
					z-index: z-index(topnav) + 1;
				}

				.topnav-smallheader-container {
					@include z-index(topnav);
					background: inherit;
					position: fixed;
					top: 0;
					width: 100%;

					@include breakpoint(medium) {
						position: relative;
					}
				}
			}

			&.sticky-medium {
				@include breakpoint(medium) {
					height: auto;
					position: fixed;
				}
			}
		}
	}
}

// To overriding the logo's image when Topnav is minimized,
// load the image as a background and hide the SVGs
@mixin topnav-sticky-minimized-logo($logo: null) {
	@if $logo {
		background: $logo left center no-repeat;
		background-size: contain;

		svg {
			display: none;
		}
	}
}

@mixin topnav-sticky-header-minimized {
	@include breakpoint(medium) {
		border-bottom: $topnav-sticky-minimized-border;

		.topnav {
			&-accordion {
				flex: auto;
				margin: $topnav-sticky-minimized-accordion--margin__medium;
				order: 2;
			}

			&-cart {

				a {
					margin: $topnav-sticky-minimized-cart--margin__medium;
				}

				&-icon {
					padding: $topnav-sticky-minimized-cart--padding__medium;
					width: $topnav-sticky-minimized-cart-icon-width__medium;
				}

				&-label {
					display: none;
				}
			}

			&-dropdown,
			&-mainnav-more-dropdown {
				border-top: $topnav-sticky-minimized-dropdown-border;
			}

			&-smallheader-container {
				@include breakpoint(medium) {
					flex-grow: 0;
					order: 1;
				}
			}

			&-logo {
				height: $topnav-sticky-minimized-logo--height__medium;
				margin: $topnav-sticky-minimized-logo--margin__medium;
				top: $topnav-sticky-minimized-logo--top__medium;
				width: $topnav-sticky-minimized-logo--width__medium;

				@include topnav-sticky-minimized-logo($topnav-sticky-minimized-logo);
			}

			&-utility {
				padding: $topnav-sticky-minimized--padding__medium;

				&-cart-container {
					display: flex;
					flex: none;
					flex-direction: row;
					order: 4;
				}

				&-item {
					height: $topnav-sticky-minimized--height__medium;
					line-height: $topnav-sticky-minimized--line-height__medium;

					&-icon {
						padding: $topnav-sticky-minimized-utility-item-link-icon--padding__medium;
						width: auto;
					}

					&-link {
						text-align: center;
						width: auto;

						&-icon-accessibility {
							width: $topnav-sticky-minimized-utility-item-link-accessibility--width__medium;
						}
					}

					&--flag-link {
						padding: $topnav-sticky-minimized-flag--padding__medium;
					}
				}
			}

			&-promobar {

				&-container {
					flex: none;
					width: 100%;
				}
			}

			&-search {
				&-container {
					clear: none;
					flex-grow: 0;
					order: 3;
					padding: $topnav-sticky-minimized-search-container--padding__medium;
					width: $topnav-sticky-minimized-search-container--width__medium;
				}

				&-button,
				&-input {
					height: $topnav-sticky-minimized-search-input-height__medium;
				}
			}

			&-mainnav {
				> .l1 {
					> .topnav-accordion-item-link,
					.topnav-mainnav-more-label {
						font-size: $topnav-sticky-minimized-l1--font-size__medium;
						padding: $topnav-sticky-minimized-l1-link--padding__medium;

						&:after {
							bottom: $topnav-sticky-minimized-l1-active-border-position-bottom__medium;
						}
					}
				}
			}

			&-wishlist-notification {
				top: $topnav-sticky-minimized--height__medium;
			}
		}
	}

	@include breakpoint(large) {
		.topnav {
			&-utility {
				display: inherit;

				&-item {
					padding: $topnav-sticky-minimized-utility-item--padding__large;
				}

				&-item-icon {
					margin: 0;
					padding: $topnav-sticky-minimized-utility-item-link-icon--padding__large;
				}

				&-item-label {
					display: none;
				}

				&-item--flag-link {
					padding: $topnav-sticky-minimized-flag--padding__large;
				}
			}

			&-cart-icon {
				padding: $topnav-sticky-minimized-cart-icon--padding__large;
				width: $topnav-sticky-minimized-cart-icon-width__large;
			}

			&-logo {
				margin: $topnav-sticky-minimized-logo--margin__large;

				@include topnav-sticky-minimized-logo($topnav-sticky-minimized-logo_large);
			}

			&-search-container {
				padding: $topnav-sticky-minimized-search-container--padding__large;
				width: $topnav-sticky-minimized-search-container--width__large;
			}

			&-mainnav {
				> .l1 {

					> .topnav-accordion-item-link,
					.topnav-mainnav-more-label {
						font-size: $topnav-sticky-minimized-l1--font-size__large;
						line-height: $topnav-sticky-minimized-l1--line-height__large;
						padding: $topnav-sticky-minimized-l1-link--padding__large;
					}
				}
			}
		}
	}
}
