//
// Drives the display of the simplelist dropdown on medium and up screens on the CMS-based
// top nav implementation.
//

// Defaults from other modules
$topnav-mainnav-more-dropdown-border: null !default;  // Match main nav defaults
$topnav-mainnav-more-dropdown-link-color: $topnav-dropdown-item-color !default;  // Match main nav defaults
$topnav-mainnav-more-dropdown-link-font-size: 15px !default;  // Match main nav defaults
$topnav-mainnav-more-dropdown-link-line-height: $topnav-mainnav-more-dropdown-link-font-size + 2 !default;  // Match main nav defaults
$topnav-mainnav-more-dropdown-link-padding: 8px 0 !default;  // Match main nav defaults
$topnav-mainnav-more-dropdown-padding: $topnav-dropdown-padding !default;  // Match main nav defaults
$topnav-mainnav-more-dropdown-width: 250px !default;  // Match main nav defaults
$topnav-dropdown-close-right__medium:  0 !default;  // Match dropdown defaults
$topnav-dropdown-close-top__medium: 0 !default;  // Match dropdown defaults
$topnav-dropdown-l3-link-hover-color: null !default;

$topnav-dropdown-list-border: $topnav-mainnav-more-dropdown-border !default;
$topnav-dropdown-list-close-right: $topnav-dropdown-close-right__medium !default;
$topnav-dropdown-list-close-top: $topnav-dropdown-close-top__medium !default;
$topnav-dropdown-list-item-color: $topnav-mainnav-more-dropdown-link-color !default;
$topnav-dropdown-list-item-color-hover: $topnav-dropdown-l3-link-hover-color !default;
$topnav-dropdown-list-item-font-size: $topnav-mainnav-more-dropdown-link-font-size !default;
$topnav-dropdown-list-item-line-height: $topnav-mainnav-more-dropdown-link-line-height !default;
$topnav-dropdown-list-item-padding: $topnav-mainnav-more-dropdown-link-padding !default;
$topnav-dropdown-list-shopall-font-size: null !default;
$topnav-dropdown-list-shopall-margin: null !default;
$topnav-dropdown-list-padding: $topnav-mainnav-more-dropdown-padding !default;
$topnav-dropdown-list-width: $topnav-mainnav-more-dropdown-width !default;


@mixin topnav-dropdown-list {
  @include breakpoint(medium) {
    border: $topnav-dropdown-list-border;
    padding: 0;
    width: $topnav-dropdown-list-width;
    z-index: z-index(topnav) +1;

    .topnav {
      &-accordion {
        &-shopall {
          .columns {
            padding: 0;
          }
        }
      }

      &-dropdown-close {
        right: $topnav-dropdown-list-close-right;
        top: $topnav-dropdown-list-close-top;
      }
    }
  }
}

@mixin topnav-dropdown-list-wrapper {
  @include breakpoint(medium) {
    padding: $topnav-dropdown-list-padding;
  }
}

@mixin topnav-dropdown-list-item {
  @include breakpoint(medium) {
    margin-bottom: 0;

    > .topnav-accordion-item-link {
      color: $topnav-dropdown-list-item-color;
      font-size: $topnav-dropdown-list-item-font-size;
      line-height: $topnav-dropdown-list-item-line-height;
      padding: $topnav-dropdown-list-item-padding;

      &:active,
      &:focus,
      &:hover {
        color: $topnav-dropdown-list-item-color-hover;
      }
    }
  }
}

@mixin topnav-dropdown-list-shopall {
  margin-bottom: $topnav-dropdown-list-shopall-margin;

  &-link {
    font-size: $topnav-dropdown-list-shopall-font-size;
  }
}

@mixin topnav-simple-list {
  .topnav {
    &-dropdown {
      &-list {
        @include topnav-dropdown-list;

        .topnav-dropdown-wrapper {
          @include topnav-dropdown-list-wrapper;
        }

        .topnav {
          &-accordion {

            &-item {
              &.l2 {
                @include topnav-dropdown-list-item;
              }
            }

            &-shopall {
              @include topnav-dropdown-list-shopall;
            }
          }
        }
      }
    }
  }
}
