//
// Drives the display of the accordion on the CMS-based
// top nav implementation.
//

@import 'global/z-indexes';

$topnav-accordion--background: $white !default;
$topnav-accordion-border-top: null !default;
$topnav-accordion-border-top__medium: null !default;
$topnav-accordion-display__medium: block !default;
$topnav-accordion-background__medium: none !default;
$topnav-accordion--font-size: 1rem !default;
$topnav-accordion--font-size__medium: rem-calc(12px) !default;
$topnav-accordion--width__medium: auto !default;
$topnav-accordion--font-size__large: null !default;
$topnav-accordion--color: inherit !default;
$topnav-accordion--color__medium: $topnav-color !default;
$topnav-accordion--bg-color__medium: transparent !default;
$topnav-accordion--item-active-bg__medium: null !default;
$topnav-accordion-item-color-active__medium: $topnav-bg !default;
$topnav-accordion-margin__medium: null !default;
$topnav-accordion-margin__large: null !default;
$topnav-search-container-max-width__medium: 15em !default; // This is a fallback in case the search SCSS is not loaded before the accordion
$topnav-accordion-item--border: 1px solid get-color(primary) !default;
$topnav-accordion-item-l2-l3--border: $topnav-accordion-item--border;
$topnav-accordion-item--height: 48px !default;
$topnav-accordion-item--indent: 0 !default; // Additional indent offset for each level
$topnav-accordion-item--line-height__medium: 13px !default;
$topnav-accordion-item--padding-base: 15px !default;
$topnav-accordion-item--padding__medium: 15px 7px 19px 7px !default;
$topnav-accordion-item--padding__large: null !default;
$topnav-accordion-indicator--height: 0.5em !default;
$topnav-accordion-indicator--width: null !default;
$topnav-accordion-indicator--arrow-icon: null !default;
$topnav-accordion-indicator-right: 0 !default;
$topnav-accordion-l2--font-size: 13px !default;
$topnav-accordion-l2--line-height: 17px !default;
$topnav-accordion-l2--font-family__medium: null !default;
$topnav-accordion-l2--margin-bottom__medium: 23px !default;
$topnav-accordion-l2-link--margin-bottom__medium: null !default;
$topnav-accordion-l2--padding__medium: 0 !default;
$topnav-accordion-l2--padding-link__medium: null !default;
$topnav-accordion-l2--font-size__medium: 15px !default;
$topnav-accordion-l2--font-weight__medium: 700 !default;
$topnav-accordion-l2--line-height__medium: 15px !default;
$topnav-accordion-l3--font-size: 14px !default;
$topnav-accordion-l3--line-height: 16px !default;
$topnav-accordion-l3--list--margin-bottom__medium: null !default;
$topnav-accordion-l3--padding__medium: 0 !default;
$topnav-accordion-l3--font-size__medium: 14px !default;
$topnav-accordion-l3--font-weight: null !default;
$topnav-accordion-l3--font-weight__medium: null !default;
$topnav-accordion-l3--line-height__medium: $topnav-accordion-l2--line-height__medium !default;
$topnav-accordion-l3--font-family__medium: null !default;
$topnav-accordion-l3--margin-bottom: $topnav-accordion-item--padding-base !default;
$topnav-accordion-l3--margin-bottom__medium: 15px !default;
$topnav-accordion-search-padding: 55px !default;
$topnav-accordion-indicator-arrow-top-position: null !default;

$topnav-accordion-shopall--font-size: 12px !default;
$topnav-accordion-shopall--font-weight: 400 !default;
$topnav-accordion-shopall--height__medium: null !default;
$topnav-accordion-shopall--line-height: 15px !default;
$topnav-accordion-shopall--font-size__medium: $topnav-accordion-l2--font-size__medium !default;
$topnav-accordion-shopall--font-weight__medium: $topnav-accordion-l2--font-weight__medium !default;
$topnav-accordion-shopall--line-height__medium: $topnav-accordion-l2--line-height__medium !default;
$topnav-accordion-shopall--margin-bottom__medium: 18px !default;
$topnav-accordion-shopall--margin-bottom__large: null !default;
$topnav-accordion-shopall--padding__medium: 0 !default;
$topnav-accordion-shopall-indicator--font-size: null !default;
$topnav-accordion-shopall-indicator--spacing: 1em !default;
$topnav-accordion-indicator-width: $topnav-button-width !default;
$topnav-accordion-indicator-arrow-font-size: null !default;
$topnav-accordion-indicator-arrow-color: null !default;
$topnav-accordion-indent-width: $topnav-accordion-indicator-width !default;
$topnav-accordion-shopall-indicator--arrow-icon: null !default;
$topnav-accordion-order-desktop: 2 !default;

// override vertical padding variables
$topnav-accordion-vertical-override: null !default;
$topnav-accordion-l1--vertical-padding-bottom: null !default;
$topnav-accordion-l1--vertical-padding-top: null !default;
$topnav-accordion-item-l1-shopall-padding-top: null !default;
$topnav-accordion-item-l1-shopall-padding-bottom: null !default;
$topnav-accordion-item-l2-shopall-padding-top: null !default;
$topnav-accordion-item-l2-shopall-padding-bottom: null !default;
$topnav-accordion-item--line-height: null !default;
$topnav-accordion-l2--vertical-padding: null !default;
$topnav-accordion-l2--vertical-padding-bottom: $topnav-accordion-l2--vertical-padding !default;
$topnav-accordion-l3--vertical-padding: null !default;
$topnav-accordion-l3--vertical-padding-bottom: $topnav-accordion-l3--vertical-padding !default;
$topnav-accordion-indicator-font-size: null !default;

// l1 active borders
$topnav-accordion-l1-active-borders: null !default;
$topnav-accordion-l1-active-border-weight__medium: null !default;
$topnav-accordion-l1-active-border-position-bottom__medium: null !default;
$topnav-accordion-l1-active-border-initial-color__medium: transparent !default;
$topnav-accordion-l1-active-border-duration__medium: null !default;
$topnav-accordion-l1-active-border-color__medium: get-color(primary) !default;
$topnav-accordion-l1-active-border-weight__large: null !default;
$topnav-accordion-l1-active-border-position-bottom__large: null !default;
$topnav-accordion-l1-active-border-initial-color__large: null !default;
$topnav-accordion-l1-active-border-duration__large: null !default;
$topnav-accordion-l1-active-border-color__large: null !default;

// transition duration for dropdown
$topnav-accordion-dropdown-transition-duration: 0.3s !default;

// max height for the dropdown when js doesn't load
$topnav-accordion-dropdown-max-height-high: 1000px !default;
$topnav-accordion-dropdown-max-height-low: 0px !default;

// Drilldown Menu Overrides
$topnav-drilldown-item--padding-base: $topnav-accordion-item--padding-base !default;
$topnav-drilldown-item--font-weight: null !default;
$topnav-drilldown-back-link-color: get-color(primary) !default;
$topnav-drilldown-title-line-height: null !default;
$topnav-drilldown-back-link-indicator-icon: null !default;
$topnav-drilldown-back-link-margin-top: null !default;
$topnav-drilldown-back-link-font-family: null !default;
$topnav-drilldown-back-link-font-size: $topnav-accordion-l3--font-size !default;
$topnav-drilldown-back-link-font-weight: null !default;
$topnav-drilldown-back-link-padding: $topnav-drilldown-item--padding-base !default;
$topnav-drilldown-back-link-padding-bottom: null !default;
$topnav-drilldown-back-link-transform: uppercase !default;
$topnav-drilldown-indicator--arrow-icon: $icon-arrow-right-indicator-open !default;
$topnav-drilldown-title-font-family: null !default;
$topnav-drilldown-title-font-size: $topnav-accordion--font-size !default;
$topnav-drilldown-title-font-weight: null !default;
$topnav-drilldown-title-padding: 0 $topnav-drilldown-item--padding-base $topnav-drilldown-item--padding-base !default;
$topnav-drilldown-title-transform: uppercase !default;
$topnav-drilldown-menu-transition-duration: 0.15s !default;
$topnav-drilldown-active-background: null !default;

$topnav-accordion-indicator-display-medium: none !default;
$topnav-accordion-l1-tablet-display: null !default;
$overlay-content: false !default;

// TopNav Accordion
@function arrowTopPosition($linkHeight, $arrowHeight) {
	@return ((em-calc($linkHeight) / 2) - $arrowHeight);
}

@keyframes slideInFromTop {
	0% {
		max-height: $topnav-accordion-dropdown-max-height-low;
	}

	75% {
		max-height: $topnav-accordion-dropdown-max-height-low;
	}

	100% {
		max-height: $topnav-accordion-dropdown-max-height-high;
	}
}

@if ($overlay-content) {

	.overlay-enabled {
		position: relative;
		transition: filter 200ms ease-out;

		&.overlay-active {

			&:before {
				background: rgba($black, 0.5);
				content: '';
				height: 100%;
				left: -10%;
				overflow-y: hidden;
				position: absolute;
				top: 0;
				width: 120%;
				z-index: z-index(topbarfilter);
			}
		}
	}

}

@mixin topnav-accordion-container {
	@include clearfix;
	background: $topnav-accordion--background;
	border-top: $topnav-accordion-border-top;
	clear: both;
	display: none;
	font-size: 0; // hack to remove inline-block spacing
	flex: 100%;
	order: 2;


	&.topnav-accordion-search {
		padding-top: $topnav-accordion-search-padding;
	}

	&.collapse {
		//Hiding the content while transitioning the height without having the content reflow with the height change
		overflow: hidden;

		&.topnav-accordion-search {
			padding-top: 0px;
		}
	}

	.no-js & {
		display: block;
		animation: slideInFromTop 12s ease-out 0s 1;
	}


	@include breakpoint(medium) {
		background: none;
		border-top: $topnav-accordion-border-top__medium;
		clear: none;
		display: block;
		flex: 1;
		flex-grow: 1;
		margin: $topnav-accordion-margin__medium;
		width: 0;

		&.collapse {
			max-height: none;

			.no-js & {
				animation: none;
			}
		}

		&.topnav-accordion-search {
			padding-top: 0;
		}
	}

	@include breakpoint(large) {
		order: $topnav-accordion-order-desktop;
		margin: $topnav-accordion-margin__large;
	}

	@media only screen and (min-width: 640px) and (max-width: 1024px) {
		@media (-webkit-min-device-pixel-ratio: 2) {
			background: $topnav-accordion-background__medium;
			display: $topnav-accordion-display__medium;
		}
    }

	.active & {
		display: block;
	}

	// Prevent normal Foundation grid padding on small screens
	// but restore for larger screen sizes. This lets the <li>
	// go 100% of the small screen width, so top/bottom borders
	// don't have gaps at the edge of the screen.
	.column,
	.columns {
		@include grid-column-gutter($gutter: 0);

		&.topnav-column-promo {
			&-media,
			&-text {
				@include grid-column-gutter($gutter: auto);
			}
		}

		@include breakpoint(medium) {
			@include grid-column-gutter($gutter: auto);

			&.topnav-column-promo {
				@include grid-column-gutter($gutter: 0);
			}
		}
	}
}

// Style the programaticly-generated Shop All
// link that shows under a menu item when opened.
// This link is for touch devices since tapping
// the menu item expands/collapses instead of
// navigating.
@mixin topnav-accordion-shopall {
	display: none; // Hide until revealed
	margin-left: auto;
	margin-right: auto;

	.l1 &,
	.l2 & {
		&-link {
			padding: {
				// overwrites for shopall vertical padding when design does not follow formula
				@if ($topnav-accordion-vertical-override == true) {
					bottom: $topnav-accordion-item-l1-shopall-padding-bottom;
					top: $topnav-accordion-item-l1-shopall-padding-top;
				} @else {
					bottom: $topnav-accordion-item--padding-base;
					top: $topnav-accordion-item--padding-base/2; // Visually match the gap between elements to the outside gap
				}
			}

			// reset padding value for medium since individual top/bottom properties take precedence
			@include breakpoint(medium) {
				padding: $topnav-accordion-shopall--padding__medium;
			}
		}
	}

	.l2 & {
		&-link {
			padding: {
				// overwrites for shopall vertical padding when design does not follow formula
				@if ($topnav-accordion-vertical-override == true) {
					bottom: $topnav-accordion-item-l2-shopall-padding-bottom;
					top: $topnav-accordion-item-l2-shopall-padding-top;
				} @else {
					bottom: $topnav-accordion-item--padding-base/2;
					top: $topnav-accordion-item--padding-base/2; // Visually match the gap between elements to the outside gap
				}
			}
		}
	}

	&-link {
		color: inherit;
		display: inline-block;
		font-size: $topnav-accordion-shopall--font-size;
		font-weight: $topnav-accordion-shopall--font-weight;
		line-height: $topnav-accordion-shopall--line-height;
		width: 100%;

		&--arrow {
			font-size: $topnav-accordion-shopall-indicator--font-size;
			padding-left: $topnav-accordion-shopall-indicator--spacing;

			&:before {
				@include icomoonFont($topnav-accordion-shopall-indicator--arrow-icon, false); // overwrite default arrow
			}
		}

		@include breakpoint(medium) {
			font-size: $topnav-accordion-shopall--font-size__medium;
			font-weight: $topnav-accordion-shopall--font-weight__medium;
			height: $topnav-accordion-shopall--height__medium;
			line-height: $topnav-accordion-shopall--line-height__medium;
			padding: $topnav-accordion-shopall--padding__medium;
			width: auto;
		}
	}

	// Reset padding and margins for larger screen sizes
	@include breakpoint(medium) {
		margin-bottom: $topnav-accordion-shopall--margin-bottom__medium;
	}

	@include breakpoint(large) {
		margin-bottom: $topnav-accordion-shopall--margin-bottom__large;
	}

	// Prevent Foundation Grid rows from pulling the shopall link
	// outside the container padding on small screens
	.row {
		margin: {
			left: 0;
			right: 0;
		}

		@include breakpoint(medium) {
			// Restore regular nested row padding
			margin: {
				left: auto;
				right: auto;
			}
		}
	}

	// show on small screens when parent is active
	.active & {
		display: block;
	}

	// Hide in L2 columns on medium and up
	.l1.active .l2 & {
		display: none;
	}
}

@mixin topnav-accordion-item {
	@include topnav-accordion-item-borders($children: true);

	position: relative;

	> .topnav-accordion-item-link,
	> .topnav-mainnav-more-label {
		font-size: $topnav-accordion--font-size;
		position: relative;
		width: 100%;
	}

	@include breakpoint(medium) {
		float: none;

		> .topnav-accordion-item-link,
		> .topnav-mainnav-more-label {
			font-size: $topnav-accordion--font-size__medium;
			width: auto;
		}
	}

	@media only screen and (min-width: 640px) and (max-width: 1024px) {
        @media (-webkit-min-device-pixel-ratio: 2) {
			> .topnav-accordion-item-link,
			> .topnav-mainnav-more-label {
				width: $topnav-accordion--width__medium;
			}
		}
    }

	@include breakpoint(large) {
		> .topnav-accordion-item-link,
		> .topnav-mainnav-more-label {
			font-size: $topnav-accordion--font-size__large;
		}
	}
}

//
// Sets the borders for the different levels
// @param $border - Border style
// @param $children - {false} flag to indicate if nested child styles should be generated
// @param $excludeFirst - {true} flag to indicate if the first item should be excluded
//
@mixin topnav-accordion-item-borders($border: $topnav-accordion-item--border, $children: false, $excludeFirst: true) {
	border-top: $topnav-accordion-item--border;

	@include breakpoint(medium) {
		border-top: 0;
	}

	// Prevent a duplicate border with the header
	@if $excludeFirst {
		&:first-of-type {
			border-top: 0;
		}
	}

	@if $children {
		// Items in child lists
		&.l2,
		&.l3 {
			// Put a border on the top of the nested list to
			// separate from the Shop All
			&:first-of-type {
				border-top: $topnav-accordion-item-l2-l3--border;

				@include breakpoint(medium) {
					border-top: 0;
				}
			}

			// Remove the border from the bottom of the list to
			// prevent double borders
			&:last-of-type {
				border-bottom: 0;
			}

			// Reset on larger screens
			@include breakpoint(medium) {
				border: 0;
			}
		}
	}
}

@mixin topnav-accordion-item-children {
	// Handles logic for show/hide of an item in the
	// accordion nav and its children
	// Hide immediate child elements that are not the direct link
	& > .topnav-dropdown,
	& > .topnav-accordion-l2-dropdown,
	& > .topnav-accordion-l3-dropdown {
		display: none;
	}

	@include breakpoint(medium) {
		& .topnav-accordion-l2-dropdown,
		& .topnav-accordion-l3-dropdown {
			display: inherit;
		}

		& .topnav-accordion-l3-dropdown {
			margin-bottom: $topnav-accordion-l3--list--margin-bottom__medium; // margin between L3 list and next L2
		}


	}

	// Show all immediate child elements when active
	&.active {
		// properties needed for js initiated animation on li element
		height: auto;
		transition: height $topnav-accordion-dropdown-transition-duration ease-out;

		& > .topnav-dropdown,
		& > .topnav-accordion-l2-dropdown,
		& > .topnav-accordion-l3-dropdown {
			display: block;
		}
	}
}

//
// Sets the colors of the links in the accordion nav
//
@mixin topnav-accordion-item-colors {
	.topnav-accordion-item-link,
	.topnav-accordion-shopall-link,
	.topnav-promo-text-link,
	.topnav-mainnav-more-label {
		color: $topnav-accordion--color;

		@include breakpoint(medium) {
			color: $topnav-accordion--color__medium;

			&:active,
			&:focus,
			&:hover {
				background-color: $topnav-accordion--item-active-bg__medium;
				color: $topnav-accordion-item-color-active__medium;
			}
		}
	}

	@include breakpoint(medium) {
		&.has-children > .topnav-accordion-item-link,
		.topnav-mainnav-more-label {
			&:hover {
				background-color: $topnav-accordion--bg-color__medium;
				color: $topnav-accordion--color__medium;
			}
		}
	}
}

//
// Rows with indicators need sufficient padding for the indicators
//
@mixin topnav-accordion-indent-has-children {
	// Leave room for the indicators giving exactly
	// the same space as the menu button resulting in clean,
	// precise alignments between rows and hamburger
	padding-#{$global-right}: $topnav-accordion-indent-width;

	@include breakpoint(medium) {
		padding-#{$global-right}: 0;
	}
}


//
// Setup the layout and padding
// for an arbitrary-depth menu item
//
@mixin topnav-accordion-l($level: 1) {
	@include topnav-accordion-item-size($level);

	display: block;

	& > .topnav-accordion-item-link,
	& > .topnav-promo-text-link,
	.topnav-accordion-shopall-link {
		@include topnav-accordion-indent-has-children;
	}

	@if $level == 1 {
		@include topnav-accordion-l1-padding;
	}

	// Prevent excessive padding between
	// menu item and its generated Shop All
	&.active {
		> .topnav-accordion-item-link,
		> .topnav-promo-text-link,
		&.more .topnav-mainnav-more-label {
			@if ($topnav-accordion-l1-active-borders == true) {
				@include breakpoint(medium) {
					&:after {
						background: $black;
					}
				}

				@include breakpoint(large) {
					&:after {
						background: $black;
					}
				}
			}

			// Placeholders don't generate shopall links but still
			// need the padding
			&.placeholder {
				padding-bottom: $topnav-accordion-item--padding-base;
			}
		}

		padding-bottom: 0; // Last child will provide bottom padding
	}

	> .topnav-accordion-item-link,
	> .topnav-promo-text-link,
	&.more .topnav-mainnav-more-label {
		@include breakpoint(medium) {
			height: inherit;

			@if ($topnav-accordion-l1-active-borders == true) {
				@include thick-underline-border($topnav-accordion-l1-active-border-weight__medium, $topnav-accordion-l1-active-border-position-bottom__medium, $topnav-accordion-l1-active-border-initial-color__medium, $topnav-accordion-l1-active-border-duration__medium);
			}

			&:focus {
				z-index: z-index(topnav) + 1;
			}
		}

		@include breakpoint(large) {
			@if ($topnav-accordion-l1-active-borders == true) {
				@include thick-underline-border($topnav-accordion-l1-active-border-weight__large, $topnav-accordion-l1-active-border-position-bottom__large, $topnav-accordion-l1-active-border-initial-color__large, $topnav-accordion-l1-active-border-duration__large);
			}
		}
	}
}

// L2 styles for small
@mixin topnav-accordion-l2-small {
	&.topnav-accordion-item {
		> .topnav-accordion-item-link {
			font-size: $topnav-accordion-l2--font-size;
			line-height: $topnav-accordion-l2--line-height;
		}
	}
}

// L3 styles for small
@mixin topnav-accordion-l3-small {
	&.topnav-accordion-item {
		> .topnav-accordion-item-link {
			font-size: $topnav-accordion-l3--font-size;
			font-weight: $topnav-accordion-l3--font-weight;
			line-height: $topnav-accordion-l3--line-height;
		}

		&:last-of-type {
			margin-bottom: $topnav-accordion-l3--margin-bottom;
		}
	}
	.topnav-promo-text-link {
		font-size: $topnav-accordion-l3--font-size;
		font-weight: $topnav-accordion-l3--font-weight;
		line-height: $topnav-accordion-l3--line-height;
	}
}

// L1 styles for medium
@mixin topnav-accordion-l1-medium {
	display: inline-block;
	width: auto;
	@media only screen and (min-width: 640px) and (max-width: 1024px) {
        @media (-webkit-min-device-pixel-ratio: 2) {
			width: $topnav-accordion-l1-tablet-display;
        }
    }
}

// L2 styles for medium
@mixin topnav-accordion-l2-medium {
	&.topnav-accordion-item {
		margin-bottom: $topnav-accordion-l2--margin-bottom__medium;

		> .topnav-accordion-item-link {
			font-family: $topnav-accordion-l2--font-family__medium;
			font-size: $topnav-accordion-l2--font-size__medium;
			font-weight: $topnav-accordion-l2--font-weight__medium;
			line-height: $topnav-accordion-l2--line-height__medium;
			margin-bottom: $topnav-accordion-l2-link--margin-bottom__medium;
			padding: $topnav-accordion-l2--padding__medium;
		}

		&:last-of-type {
			margin-bottom: 0;

			// remove margin if added to L3 list within the last L2
			@if ($topnav-accordion-l3--list--margin-bottom__medium) {
				.topnav-accordion-l3-dropdown {
					margin-bottom: 0;
				}
			}
		}
	}
}

@mixin top-nav-accordion-font-styles {
	font-family: $topnav-accordion-l3--font-family__medium;
	font-size: $topnav-accordion-l3--font-size__medium;
	font-weight: $topnav-accordion-l3--font-weight__medium;
	line-height: $topnav-accordion-l3--line-height__medium;
	margin-bottom: $topnav-accordion-l3--margin-bottom__medium;
	padding: $topnav-accordion-l3--padding__medium;
}

// L3 styles for medium
@mixin topnav-accordion-l3-medium {
	&.topnav-accordion-item {
		padding: 0;

		> .topnav-accordion-item-link {
			@include top-nav-accordion-font-styles;
		}

		&:last-of-type {
			margin-bottom: 0;

			> .topnav-accordion-item-link {
				margin-bottom: 0;
			}
		}
	}
    > .topnav-promo-text-link {
		@include top-nav-accordion-font-styles;
		display: block;
	}

}

// L1 styles for large
@mixin topnav-accordion-l1-padding {
	> .topnav-accordion-item-link,
	&.more .topnav-mainnav-more-label {

		@include breakpoint(medium) {
			padding: $topnav-accordion-item--padding__medium;
			white-space: nowrap;
		}

		@include breakpoint(large) {
			padding: $topnav-accordion-item--padding__large;
		}
	}
}

//
// Sets the horizontal padding on a menu item
// @param $level - Depth of the targeted menu item
// @param $indent - Additional indentation offset
// @param $padding - Normal padding around the entire item
//
@mixin topnav-accordion-indent($level: 1, $indent: $topnav-accordion-item--indent, $padding: $topnav-accordion-item--padding-base) {
	display: inline-block; // keeps word wrapping aligned
	// Left padding provides the indentation
	$padding-indent: $level * ($indent + $padding);
	padding-#{$global-left}: $padding-indent;
	// Right padding matches the default
	padding-#{$global-right}: $padding;

	// Reset padding for large screen sizes
	@include breakpoint(medium) {
		padding-#{$global-left}: 0;
		padding-#{$global-right}: 0;
	}
}

//
// Sets the size of a link using padding
// and indentation levels
// @param $level - Depth of the targeted menu item
// @param $indent - Additional indentation offset
// @param $padding - Desired level of padding around all 4 sides
// @param $height - Optimimum height of the menu item
//
@mixin topnav-accordion-item-size($level: 1, $indent: $topnav-accordion-item--indent, $padding: $topnav-accordion-item--padding-base, $height: $topnav-accordion-item--height, $link: "& > a") {
	// Calculate the line height and padding
	// pasted on the desired total height and
	// and desired padding.
	//
	// Note that we need to gracefully handle
	// text that wraps to multiple lines, so line
	// height can't just be the height of the item
	$padding-vert: $padding;
	$line-height: $topnav-accordion-item--height - (2 * $topnav-accordion-item--padding-base);

	// Vertical padding is on the <a>
	#{$link} {
		padding: {
			bottom: $padding-vert;
			top: $padding-vert;
		}

		@if ($topnav-accordion-vertical-override == true) {
			@if ($level == 1) {
				// overwrite l1 padding if they differ from l1 base padding or do not match each other
				padding: {
					bottom: $topnav-accordion-l1--vertical-padding-bottom;
					top: $topnav-accordion-l1--vertical-padding-top;
				}
			}

			@if ($level == 2) {
				// overwrite l2 padding if they differ from l1 base padding
				padding: {
					bottom: $topnav-accordion-l2--vertical-padding-bottom;
					top: $topnav-accordion-l2--vertical-padding;
				}
			}

			@if ($level == 3) {
				// overwrite l3 padding if they differ from l1 base padding
				padding: {
					bottom: $topnav-accordion-l3--vertical-padding-bottom;
					top: $topnav-accordion-l3--vertical-padding;
				}
			}
		}
	}

	// Set line-height on <li> for link and icons
	@if ($topnav-accordion-vertical-override == true) {
		// overwrite line height
		line-height: $topnav-accordion-item--line-height;
	} @else {
		// use calculated line height
		line-height: $line-height;
	}

	width: 100%; // Make the entire <li> tappable

	// Horizontal padding is on the child elements
	& > .topnav-accordion-item-link,
	& > .topnav-accordion-shopall a, // l2/l3 shop-all links
	& .topnav-dropdown-wrapper > .topnav-accordion-shopall a { // l1 shop-all links (extra specificity needed to ensure styling is applied correctly based on appropriate $level)
		@include topnav-accordion-indent($level, $indent, $padding);
	}

	& > .topnav-promo-text-link {
		@include topnav-accordion-indent($level, $indent, $padding);
		padding-left: 0;
		display: block;
	}

	@include breakpoint(medium) {
		line-height: $topnav-accordion-item--line-height__medium;
	}
}

//
// Styles the arrows that indicate an element has children
// and the state of the accordion
//
@mixin topnav-accordion-indicator {
	display: inline-block;
	font-size: $topnav-accordion-indicator-font-size;
	height: $topnav-accordion-indicator--height;
	pointer-events: none; // Prevents iOS from triggering :hover on tap
	position: absolute;
	@if ($topnav-accordion-indicator-arrow-top-position) {
		top: $topnav-accordion-indicator-arrow-top-position;
	} @else {
		top: arrowTopPosition($topnav-accordion-item--height, $topnav-accordion-indicator--height);
	}
	width: $topnav-accordion-indicator--width;
	#{$global-right}: $topnav-accordion-indicator-right;


	// Center align the indicator icon to the hamburger icon
	&::before {
		@include icomoonFont($topnav-accordion-indicator--arrow-icon, false); // overwrite default arrow
		color: $topnav-accordion-indicator-arrow-color;
		display: block;
		font-size: $topnav-accordion-indicator-arrow-font-size;
		text-align: center;
		width: $topnav-accordion-indicator-width;
	}

	// Rotate arrows when open
	.active > .topnav-accordion-item-link > & {
		height: auto;
		transform: rotate(180deg);
	}

	@include breakpoint(medium) {
		display: none;
	}
	@media only screen and (min-width: 640px) and (max-width: 1024px) {
        @media (-webkit-min-device-pixel-ratio: 2) {
			display: $topnav-accordion-indicator-display-medium;
        }
    }
}


@mixin topnav-drilldown {
	.drilldown {
		&-container {
			overflow: hidden;
			position: relative;
			transition: height $topnav-drilldown-menu-transition-duration linear;
			width: 100%;
			@media only screen and (min-width: 640px) and (max-width: 1024px) {
				@media (-webkit-min-device-pixel-ratio: 2) {
					overflow: unset;
				}
			}
		}

		&-menu {
			display: block;
			left: 100%;
			position: absolute;
			top: 0;
			width: 100%;
			visibility: hidden;

			&.root {
				display: block;
				left: 0;
				transform: translateX(0);
				transition: transform $topnav-drilldown-menu-transition-duration linear;
				visibility: visible;

				&[data-menu-level="1"] {
					transform: translateX(-100%);
				}

				&[data-menu-level="2"] {
					transform: translateX(-200%);
				}
			}

			&.active {
				background-color: $topnav-drilldown-active-background;
				visibility: visible;
			}

			&-title {
				font-family: $topnav-drilldown-title-font-family;
				font-size: $topnav-drilldown-title-font-size;
				font-weight: $topnav-drilldown-title-font-weight;
				line-height: $topnav-drilldown-title-line-height;
				padding: $topnav-drilldown-title-padding;
				text-transform: $topnav-drilldown-title-transform;
			}
		}

		&-back {
			&-indicator {
				&:before {
					@include icomoonFont($topnav-drilldown-back-link-indicator-icon, false);
				}
			}

			&-link {
				color: $topnav-drilldown-back-link-color;
				display: block;
				font-family: $topnav-drilldown-back-link-font-family;
				font-size: $topnav-drilldown-back-link-font-size;
				font-weight: $topnav-drilldown-back-link-font-weight;
				margin-top: $topnav-drilldown-back-link-margin-top;
				padding: $topnav-drilldown-back-link-padding;
				padding-bottom: $topnav-drilldown-back-link-padding-bottom;
				text-transform: $topnav-drilldown-back-link-transform;
			}
		}
	}

	.topnav-accordion {
		&-indicator {
			&:before {
				@include icomoonFont($topnav-drilldown-indicator--arrow-icon, false);
			}
		}

		&-item {
			position: static; // allows all drilldown menus to be positioned at top
		}
	}

	&.topnav-accordion {
		// in the accordion display links are indented further each level so for the
		// drilldown menu we want to align them all equally
		.l2,
		.l3 {
			.topnav-accordion-item-link,
			.topnav-accordion-shopall-link {
				padding-left: $topnav-drilldown-item--padding-base;
				font-weight: $topnav-drilldown-item--font-weight;
			}
		}
	}
}

@mixin topnav-accordion {
	.topnav {
		&-accordion {
			@include topnav-accordion-container;

			&-item {
				@include topnav-accordion-item;
				@include topnav-accordion-item-children;
				@include topnav-accordion-item-colors;
			}

			&-indicator {
				@include topnav-accordion-indicator;
			}

			&-shopall {
				@include topnav-accordion-shopall;
			}

			.l1 {
				@include topnav-accordion-l(1);
				@include topnav-accordion-l1-padding;

				@include breakpoint(medium) {
					@include topnav-accordion-l1-medium;
				}
			}

			.l2 {
				@include topnav-accordion-l(2);
				@include topnav-accordion-l2-small;

				@include breakpoint(medium) {
					@include topnav-accordion-l2-medium;
				}
			}

			.l3, .topnav-column-promo-text {
				@include topnav-accordion-l(3);
				@include topnav-accordion-l3-small;

				@include breakpoint(medium) {
					@include topnav-accordion-l3-medium;
				}
			}
		}

		&-drilldown {
			@include topnav-drilldown();
		}

		&-header-screen {
			@include z-index(stickyfilter);
			background: $reveal-overlay-background;
			bottom: 0;
			cursor: pointer;
			display: none;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
		}

		&.active {
			.peek-a-boo-menu {
				.topnav-accordion {
					@include z-index(topnav);
					bottom: 0;
					display: block;
					left: 0;
					overflow-y: auto;
					position: fixed;
					right: 0;
					top: $topnav-button-height; // set top offset for the menu
				}

				.topnav-header-screen {
					display: block;
				}
			}
		}
	}

	.peek-a-boo-enabled {
        overflow: hidden;
    }
}
