//
// Drives the display of the search button on the CMS-based
// top nav implementation.
//

$topnav-search-container-background: $white !default;
$topnav-search-container-padding: 10px !default;
$topnav-search-container-padding__medium: 3px 10px !default;
$topnav-searchbutton--color: get-color(primary) !default;
$topnav-search-button-text-font-size: 14px !default;
$topnav-search-button-text-font-size__medium: null !default;
$topnav-search-button-text-font-family: null !default;
$topnav-search-button-text-font-weight: 300 !default;
$topnav-search-button-height: 36px !default;
$topnav-search-input-line-height: $topnav-search-button-height !default;
$topnav-search-button-text-placeholder-color: null !default;
$topnav-search-button-icon-color: $input-color !default;
$topnav-search-button-height__medium: $topnav-search-button-height !default;
$topnav-search-input-line-height__medium: $topnav-search-button-height__medium !default;
$topnav-search-button-padding: 0 1em !default;
$topnav-search-button-padding__medium: 5px !default;
$topnav-search-button-bg: $input-background !default;
$topnav-search-border-color: get-border-value($input-border, 'color') !default;
$topnav-search-input-border-color: $topnav-search-border-color !default;
$topnav-search-container-width__medium: 25% !default;
$topnav-search-container-width__large: null !default;
$topnav-search-container-padding__large: null !default;
$topnav-search-button-height__large: null !default;
$topnav-search-input-line-height__large: $topnav-search-button-height__large !default;
$topnav-search-input-padding-left__medium: null !default;
$topnav-search-button-padding__large: null !default;
$topnav-search-menu-border: null !default;
$topnav-search-form-focus-outline: $defaultFocusColor auto 1px !default;
$topnav-search-container-order: 3 !default;
$topnav-search-input-focus-placeholder-color: null !default;
$topnav-search-button-display: null !default;
$topnav-search-button-align: null !default;
$topnav-search-container-flex__medium: none !default;
$topnav-search-container-display__medium: block !default;

// styles for search clear button
$topnav-search-clear-button-background: $input-background !default;
$topnav-search-clear-button-border: $input-border !default;
$topnav-search-clear-icon-color: $white !default;
$topnav-search-clear-icon-background: $medium-gray !default;
$topnav-search-clear-icon-size: 22px !default;

@import 'util/common-mixins';
@import 'global/z-indexes';

// TopNav Search Container
// Styles the search container and its contents
@mixin topnav-search-container {
	.topnav .topnav-search-container {
		@include clearfix;
		background: $topnav-search-container-background;
		clear: both;
		flex: 100%;
		display: none;
		order: 4;
		padding: $topnav-search-container-padding;
		position: relative;

		&.active {
			display: block;
		}

		&.topnav-menu-search {
			border-bottom: $topnav-search-menu-border;
			position: fixed;
		    width: 100%;
			z-index: z-index(topnav) + 1;
		}

		.topnav-search-form {
			display: flex;
			margin: 0;
			overflow: hidden; // keep outline shaped correctly

			&.focus {
				outline: $topnav-search-form-focus-outline;
			}
		}

		.topnav-search-button {
			font-family: $topnav-search-button-text-font-family;
			font-size: $topnav-search-button-text-font-size;
			height: $topnav-search-button-height;
			line-height: $topnav-search-button-height;
			margin: 0;
			padding: $topnav-search-button-padding;
			width: initial;

			&.search-clear-button {
			    background-color: $topnav-search-clear-button-background;
			    border: $topnav-search-clear-button-border;
			    border-left: 0;
			}

			.icon-close {
				background-color: $topnav-search-clear-icon-background;
				border-radius: $topnav-search-clear-icon-size;
			    color: $topnav-search-clear-icon-color;
			    width: $topnav-search-clear-icon-size;
			    height: $topnav-search-clear-icon-size;
			    display: inline-block;
			    line-height: $topnav-search-clear-icon-size;
			}
		}

		.topnav-search-input-container {
			overflow: hidden;
			width: 100%;
		}

		.topnav-search-input {
			@include placeholder($topnav-search-button-text-placeholder-color);
			height: $topnav-search-button-height;
			line-height: $topnav-search-input-line-height;
			margin: 0;
			outline: none;

			&:focus {
				border-color: $topnav-search-input-border-color;
				&::placeholder {
					color: $topnav-search-input-focus-placeholder-color;
				}
			}
		}

		.topnav-search-button-text {
			font-weight: $topnav-search-button-text-font-weight;
		}

		@include breakpoint(medium) {
			background: none;
			display: block;
			flex: none;
			float: $global-right;
			order: 3;
			padding: $topnav-search-container-padding__medium;
			width: $topnav-search-container-width__medium;

			.topnav-search-button {
				background-color: $topnav-search-button-bg;
				border-color: $topnav-search-border-color;
				border-left: 0;
				border-width: get-border-value($input-border, 'width');
				color: $topnav-search-button-icon-color;
				font-size: $topnav-search-button-text-font-size__medium;
				height: $topnav-search-button-height__medium;
				line-height: $topnav-search-button-height__medium;
				padding: 0 $topnav-search-button-padding__medium;
				display: $topnav-search-button-display;
				align-items: $topnav-search-button-align;
			}

			.topnav-search-input {
				height: $topnav-search-button-height__medium;
				line-height: $topnav-search-input-line-height__medium;
				padding-left: $topnav-search-input-padding-left__medium;
			}
		}

		@media only screen and (min-width: 640px) and (max-width: 1024px) {
			@media (-webkit-min-device-pixel-ratio: 2) {
				display: $topnav-search-container-display__medium;
				flex: $topnav-search-container-flex__medium;
			}
		}

		@include breakpoint(large) {
			order: $topnav-search-container-order;
			padding: $topnav-search-container-padding__large;
			width: $topnav-search-container-width__large;

			.topnav-search {
				&-button,
				&-input {
					height: $topnav-search-button-height__large;
					line-height: $topnav-search-input-line-height__large;
				}

				&-button {
					padding: 0 $topnav-search-button-padding__large;
				}
			}
		}
	}
}

