//
// Drives the display of the main menu L1 items and More menu on the CMS-based
// top nav implementation.
//

@import 'global/z-indexes';

$topnav-mainnav-l1-height: 50px !default;
$topnav-mainnav-more-dropdown-width: 250px !default;
$topnav-mainnav-more-dropdown-border: null !default;
$topnav-mainnav-more-dropdown-close-padding__medium: $topnav-dropdown-padding !default;
$topnav-mainnav-more-dropdown-close-padding__large: null !default;
$topnav-mainnav-more-dropdown-close-right__medium: $topnav-dropdown-close-right__medium !default;
$topnav-mainnav-more-dropdown-close-right__large: null !default;
$topnav-mainnav-more-dropdown-close-top__medium: $topnav-dropdown-close-top__medium !default;
$topnav-mainnav-more-dropdown-close-top__large: null !default;
$topnav-mainnav-more-dropdown-link-color: $topnav-dropdown-item-color !default;
$topnav-mainnav-more-dropdown-link-color-hover: null !default;
$topnav-mainnav-more-dropdown-link-font-size: 15px !default;
$topnav-mainnav-more-dropdown-link-line-height: $topnav-mainnav-more-dropdown-link-font-size + 2 !default;
$topnav-mainnav-more-dropdown-link-padding: 8px 0 !default;
$topnav-mainnav-more-dropdown-padding: $topnav-dropdown-padding !default;
$topnav-mainnav-more-dropdown-bg: $topnav-dropdown-bg-color !default;
$topnav-mainnav-height__medium: $topnav-mainnav-l1-height !default;
$topnav-mainnav-height__large: null !default;

@mixin topnav-mainnav-more-menu {
	.l1.topnav-accordion-item {
		@include topnav-mainnav-more-menu-item;

		// Children never display in the more menu
		.topnav-dropdown {
			display: none;
		}

		> .topnav-accordion-item-link {
			@include topnav-mainnav-more-menu-item-link;
		}
	}
}

@mixin topnav-mainnav-more-menu-item-link {
	color: $topnav-mainnav-more-dropdown-link-color;
	font-size: $topnav-mainnav-more-dropdown-link-font-size;
	line-height: $topnav-mainnav-more-dropdown-link-line-height;
	padding: $topnav-mainnav-more-dropdown-link-padding;

	&:active,
	&:focus,
	&:hover {
		color: $topnav-mainnav-more-dropdown-link-color-hover;
	}
}

@mixin topnav-mainnav-more-menu-item {
	display: inline-block;
	float: none;
	width: 100%;
}

@mixin topnav-mainnav-more-dropdown {
	background: $topnav-mainnav-more-dropdown-bg;
	border: $topnav-mainnav-more-dropdown-border;
	display: none;
	height: auto;
	overflow: hidden;
	padding: 0;
	position: absolute;
	transition: height $topnav-dropdown-transition-duration ease-out;
	width: $topnav-mainnav-more-dropdown-width;
	z-index: z-index(topnav) +1;

	.l1.more.active > & {
		display: block;
	}
}

@mixin topnav-mainnav-more-dropdown-wrapper {
	padding: $topnav-mainnav-more-dropdown-padding;
}

@mixin topnav-mainnav-more {
	&-menu {
		@include topnav-mainnav-more-menu;
	}

	&-label {
		display: block;
	}

	&-dropdown {
		@include topnav-mainnav-more-dropdown;

		&-wrapper {
			@include topnav-mainnav-more-dropdown-wrapper;
		}

		> a {
			color: $topnav-mainnav-more-dropdown-link-color;
		}

		&-close {
			@include topnav-dropdown-close(
				$topnav-mainnav-more-dropdown-close-padding__medium,
				$topnav-mainnav-more-dropdown-close-padding__large,
				$topnav-mainnav-more-dropdown-close-top__medium,
				$topnav-mainnav-more-dropdown-close-top__large,
				$topnav-mainnav-more-dropdown-close-right__medium,
				$topnav-mainnav-more-dropdown-close-right__large
			);
		}
	}
}

@mixin topnav-mainnav {
	.topnav {
		&-mainnav {
			@include breakpoint(medium) {
				&-more {
					@include topnav-mainnav-more;
				}

			}

			@include breakpoint(large) {
				height: $topnav-mainnav-height__large;
			}

			@include topnav-mainnav-centered;
		}
	}
}

@mixin topnav-mainnav-centered {
	@include breakpoint(medium) {
		.centermenu & {
			font-size: 0; // necessary to remove extra space around inline-block l1s that are no longer floated, but should rest against each other seamlessly
			margin-#{$global-left}: 0;
			margin-#{$global-right}: 0;
			text-align: center;

			.l1 {
				float: none;
				font-size: $topnav-accordion--font-size__medium; // reintroduce font size to L1s
				text-align: $global-left;
			}

			.topnav-accordion-item {
				text-align: $global-left; // reset all items in dropdown to align left
			}
		}
	}
}
