//
// Drives the display of the accordion on the CMS-based
// top nav implementation.
//

@import 'global/topnav/accordion'; // Get shared component

@mixin topnav-napa-accordion-mainnav {
  border-bottom: $topnav-accordion-item--border;

  @include breakpoint(medium) {
    border: 0;
  }
}

@mixin topnav-napa-accordion-indicator {
  font-size: 11px;
  top: 20px;
}

@mixin topnav-napa-accordion-item-link {
  display: block;

  &:hover {
    text-decoration: underline;
  }
}

@mixin topnav-napa-accordion-l1-small {
  &.topnav-accordion-item {

    & > .topnav-accordion-item-link {
      line-height: $topnav-accordion-item--line-height;

      &:active,
      &:focus,
      &:hover {
        text-decoration: underline;

        @include breakpoint(medium) {
          text-decoration: none;
        }
      }

      @include breakpoint(medium) {
        line-height: inherit;
      }
    }

    // align the L1 Shop-All link with the L2s on
    .topnav-dropdown-wrapper {
      > .topnav-accordion-shopall {
        a {
          padding-left: $topnav-accordion-item--padding-base * 2;

          @include breakpoint(medium) {
            padding-left: 0;
          }
        }
      }
    }

    // give shop-all links the accordion styles
    .topnav-accordion-shopall {
      border-top: $topnav-accordion-item--border;
      line-height: 18px;

      &-link {
        display: block;
        font-size: $topnav-accordion-l2--font-size;

        &--arrow {
          display: none;

          @include breakpoint(medium) {
            display: inline;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }

      @include breakpoint(medium) {
        border: 0;

        &-link {
          padding: 0;
        }
      }
    }

    &.active > .topnav-accordion-item-link {
      color: get-color(burnt-orange);

      @include breakpoint(medium) {
        color: $black;
      }
    }
  }
}

@mixin topnav-napa-accordion-l2-small {
  &.topnav-accordion-item {

    & > .topnav-accordion-item-link {
      @include breakpoint(medium) {
        color: $medium-gray;
      }
    }

    &.active > .topnav-accordion-item-link {
      color: get-color(burnt-orange);
      margin-bottom: $topnav-accordion-l3--margin-bottom;
    }

    .topnav-accordion-shopall {
      border-top: 0;

      &-link {
        font-size: $topnav-accordion-l3--font-size;
        font-weight: 400;
      }
    }
  }
}

@mixin topnav-napa-accordion-l3-small {
  &.topnav {
    &-accordion {
      &-item {
        border: 0;

        > .topnav-accordion-item-link {
          font-weight: 400;
          padding-left: $topnav-accordion-item--padding-base * 2;
        }
      }
    }
  }
}

@mixin topnav-napa-accordion-l3-medium {
  &.topnav {
    &-accordion {
      &-item {

        > .topnav-accordion-item-link {
          padding: 0;
        }
      }
    }
  }
}

@mixin topnav-napa-accordion-promo-text {
  p {
    line-height: 1.25em;
    margin-bottom: 5px;
  }

  .button {
    margin-bottom: 0;
  }
}

@mixin topnav-accordion-napapijri-eu {
  .topnav {
    &-accordion {
      .topnav-mainnav {
        @include topnav-napa-accordion-mainnav;
      }

      &-indicator {
        @include topnav-napa-accordion-indicator;
      }

      &-item-link {
        @include topnav-napa-accordion-item-link;
      }

      .l1 {
        @include topnav-napa-accordion-l1-small;
      }

      .l2 {
        @include topnav-napa-accordion-l2-small;
      }

      .l3 {
        @include topnav-napa-accordion-l3-small;
        @include breakpoint(medium) {
          @include topnav-napa-accordion-l3-medium;
        }
      }

      .topnav-column-promo-text{
        .topnav-promo-text-link {
          font-weight: 200;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &-4column-promo-text {
      @include topnav-napa-accordion-promo-text;
    }
  }
}

@mixin new-design-napa-l1 {
  &.active {
    .topnav-dropdown {
      background: $topnav-bg; // applies background to L2s/L3s leaving L1s white
      box-shadow: 0 rem-calc(10) rem-calc(22) rem-calc(-9) rgba($black,0.08);
    }
  }

  .topnav-accordion-shopall {
    border: none;
  }
}

@mixin new-design-napa-l2 {
  @include breakpoint(medium) {
    > .topnav-accordion-item-link {
      padding: rem-calc(18) 0;
    }
  }
}
.category-orange {
  color: get-color(napa-orange) !important; // sass-lint:disable-line no-important
    &:hover {
      text-decoration-color: $black !important; // sass-lint:disable-line no-important
    }
}
  .napa-top-nav-new-design {
    $l1-font-family: get-font('AkkuratPro');
    $shop-l2-l3-font-family: get-font('AkkuratPro');
    $topnav-accordion-l3--font-family__medium: get-font('AkkuratPro');
    $topnav-accordion--font-size__medium: rem-calc(16);
    $topnav-accordion--color__medium: $topnav-color;
    $topnav-accordion-l2--padding__medium: 0;
    $topnav-accordion-l2--font-family__medium: get-font('AkkuratPro');
    $topnav-accordion-l2--font-size__medium: rem-calc(16);
    $topnav-accordion-l3--line-height: rem-calc(25);
    $topnav-accordion-item--border: 0;
    $topnav-accordion--font-size: rem-calc(20px);
    .topnav-accordion-l3-dropdown {
      margin-top: rem-calc(10);
    }
    .topnav-accordion-item-link,.topnav-mainnav-more-label {
      font-family: get-font('AkkuratPro') !important; // sass-lint:disable-line no-important
    
      &:before {
        font-family: #{$icomoon-font-family} !important; // sass-lint:disable-line no-important
        padding-right: rem-calc(5);
      }
    }
    .topnav-accordion .l1.active>.topnav-accordion-item-link.category-orange:after {
      background: get-color(napa-orange) !important; // sass-lint:disable-line no-important
    }
    .topnav-accordion .l2.topnav-accordion-item {
      margin-bottom: 0 !important; // sass-lint:disable-line no-important
      padding-bottom: rem-calc(6) !important;
      @include breakpoint(medium) {
        padding-bottom: rem-calc(16) !important;
      }
    }
    .topnav-accordion .l3.topnav-accordion-item {
      margin-bottom: rem-calc(4);
    }
    .topnav-accordion-item {
      > .topnav-accordion-item-link:not(.category-orange),
		  > .topnav-mainnav-more-label {
			  font-size: $topnav-accordion--font-size__medium;
        font-weight: 700;
        color: $black !important; // sass-lint:disable-line no-important
        @include breakpoint(medium) {
          font-weight: 700;
        }
      }
    }
    .topnav-accordion {
      &-item {
        &.l1 {
          @include new-design-napa-l1;
        }
      }
      &-shopall {
        &-link {
          @include breakpoint(medium) {
            font-size: rem-calc(16);
            line-height: rem-calc(20);
            letter-spacing: rem-calc(0.26);
            font-family: $shop-l2-l3-font-family;
          }
        }
      }

      @include breakpoint(large) {
        order: 3;
      }
    }

    .topnav-drilldown {
      .drilldown-back-link {
        color: $black;
        font-family: $new-napa-design-font-family;
        font-size: rem-calc(9);
        font-weight: 400;  
        line-height: rem-calc(16);
        padding: rem-calc(16) 0 rem-calc(16) rem-calc(16);
        
      }

      .drilldown-menu {
        padding-bottom: 0;
        padding-top: 0;
        border-bottom: 0;
      }

      .drilldown-menu-title {
        color: get-color(secondary);
        font-family: $new-napa-design-font-family;
        font-size: rem-calc(20);
        line-height: rem-calc(24);
        padding-left: rem-calc(24);
        padding-bottom: rem-calc(10);
        display: none;
      }

      &.topnav-accordion {
        .l2 {
          .topnav-accordion-item-link {
            color: get-color(secondary);
            line-height: rem-calc(18);
            padding-bottom: rem-calc(14);
            padding-left: rem-calc(24);
            padding-top: 0;
          }

          .topnav-accordion-shopall-link {
            color: get-color(secondary);
            font-family: $new-napa-design-font-family;
            font-size: rem-calc(16);
            font-weight: 700;
            line-height: rem-calc(24);
            padding-left: rem-calc(24);
            padding-top: 0;
          }

        }

        .l3 {
          .topnav-accordion-item-link {
            color: get-color(secondary);
            font-size: rem-calc(12);
            line-height: rem-calc(7);
            padding-bottom: rem-calc(14);
          }

          &.icon-search {
            .topnav-accordion-item-link {
              padding-left: rem-calc(30);
              padding-right: rem-calc(5);
              @include breakpoint(medium) {
                padding-left: rem-calc(4);
              }
            }
          }

          &:last-child {
            .topnav-accordion-item-link {
              padding-bottom: rem-calc(22.19);
            }
          }
        }

        .columns:last-child {
          .l2:last-child {
            .topnav-accordion-item-link {
              padding-bottom: rem-calc(16);
            }
          }
        }
      }
    }
    
    .topnav-dropdown-list {
      .topnav-accordion {
        &-item {
          &.l2 {
            @include new-design-napa-l2;
          }
        }
        
        &-shopall {
          @include breakpoint(medium) {
            padding: rem-calc(13) 0;
          }
        }
      }
    }
    .topnav-accordion {
      .l1 > .topnav-accordion-item-link{
        margin-left: 0;
        line-height: rem-calc(20);
        padding: rem-calc(16);
        @include breakpoint(medium) {
          line-height: rem-calc(18);
          padding: rem-calc(16) rem-calc(8);
        }
      }
      .l1 {
        &.active {
          .topnav-accordion-item-link {
            .transperant-topnav & {
              @include breakpoint(medium) {
                &::after {
                  background: get-color(secondary);
                }
              }
            }
          }
        }
      }
    }
    
    .topnav-drilldown {
      .topnav-accordion-item {
        border: none;
      }
    }
    
    .topnav-accordion-item {
      border: none;
    }
    .topnav-accordion-indicator {
      font-size: rem-calc(16);
      top: rem-calc(18);
      padding-right: rem-calc(4);
    }
  }
  .topnav {
    &.active {
      .napa-top-nav-new-design {
        &.peek-a-boo-menu {
          .topnav-accordion {
            top: rem-calc(100);
          }

          .topnav-promobar-container {
            position: fixed;
            top: rem-calc(0);
            z-index: z-index(topnav);
          }

            .topnav-accordion-item {
              .l2, .l3 {
                &:first-of-type {
                  border-top: none;
                }

                .topnav-accordion-item-link {
                  padding: rem-calc(16) !important;
                  font-size: rem-calc(16) !important;
                  line-height: rem-calc(20);
                  font-weight: 300;
                }
              }
              &.l1 {
                .topnav-accordion-shopall {
                  border-top: none;
                }
                
                .topnav-accordion-item-link {
                  line-height: rem-calc(20) !important; // sass-lint:disable-line no-important
  
                  &:active,
			            &:focus,
			            &:focus-within,
			            &:hover {
                    text-decoration: none;

                    @include breakpoint(medium) {
                      text-decoration: underline;
                    }
                } 
              }
}

              .drilldown-back-link {
                font-size: rem-calc(16);
                font-weight: 500;
                line-height: rem-calc(20);
                font-style: normal;
                text-transform: capitalize;
                min-height: rem-calc(58);
                display: flex;
                align-items: center;

                .drilldown-back-text {
                  margin-left: rem-calc(10);
                }
              }

              .topnav-accordion-shopall-link {
                padding: rem-calc(16) !important; // sass-lint:disable-line no-important
                font-size: rem-calc(16) !important; // sass-lint:disable-line no-important
                font-weight: 700 !important; // sass-lint:disable-line no-important
                line-height: rem-calc(20);
                color: get-color(primary);
              }
            }

            .icon-search, .topnav-cart-icon {
              display: none;
            }
        }

        &.sticky.sticky-small {
          .topnav-smallheader-container {
            top: rem-calc(41);
            z-index: 101;
            box-shadow: none;
          }

          .topnav-cart {
            top: auto;
          }
        }
      }
    }
}

.topnav {

  &.new-transperant-topnav,
  .new-transperant-topnav & {
    .napa-top-nav-new-design {
      .l1 {
        &.active {
          .topnav-accordion-item-link {
            @include breakpoint(medium) {
              &::after {
                background: get-color(primary);
              }
            }
          }
        }
      }
      
      .topnav-accordion .l1>.topnav-promo-text-link:after,
      .topnav-accordion .l1 > .topnav-accordion-item-link,
      .topnav-accordion .l1.more .topnav-mainnav-more-menu .topnav-accordion-item-link:after{
        background: transparent;
      }

      .topnav-accordion .l2>.topnav-accordion-item-link:after,
      .topnav-accordion .l2>.topnav-promo-text-link:after,
      .topnav-accordion .l2.more .topnav-mainnav-more-label:after {
        background: transparent;
      }

      .topnav-accordion .l3>.topnav-accordion-item-link:after,
      .topnav-accordion .l3>.topnav-promo-text-link:after,
      .topnav-accordion .l3.more .topnav-mainnav-more-label:after,
      .topnav-accordion .topnav-column-promo-text>.topnav-accordion-item-link:after,
      .topnav-accordion .topnav-column-promo-text>.topnav-promo-text-link:after,
      .topnav-accordion .topnav-column-promo-text.more .topnav-mainnav-more-label:after {
        background: transparent;
      }
    }
  }
}