
.mini-cart {
  &-item {
    position: relative;

    &-col {
      position: relative;
    }

    &-name {
      font-size: em-calc(13px);
      padding-bottom: 10px;
    }

    &-style,
    &-attr,
    &-price,
    &-qty {
      font-size: em-calc(11px);
    }

    &-price {
      bottom: 0;
      font-weight: bold;
      position: absolute;
      right: 0;
    }
  }

  &-subtotal {
    font-weight: normal;
    padding-bottom: 10px;

    &-container {
      border-top: 2px solid $black;
      clear: both;
      padding: 20px 0;
    }

    &-price {
      float: right;
      font-size: em-calc(16px);
      font-weight: bold;
    }
  }
}
