//
// Drives the display of the cart button on the CMS-based
// top nav implementation.
//

// Defaults from other modules
$topnav-utility-item--font-size: rem-calc(12px) !default;
$topnav-utility-item-height--medium: 43px !default;
$topnav-utility-item-height--large: null !default;
$topnav-menubutton-text-width: 60px !default;

// Sizes for demo only. Need to make configurable based on designs
$topnav-cart-label--font-size: $topnav-utility-item--font-size !default;
$topnav-cart-label--font-size__large: null !default;
$topnav-cart-height: $topnav-button-height !default;
$topnav-cart-height__medium: $topnav-utility-item-height--medium !default; // Match utility nav
$topnav-cart-height__tablet: null !default;
$topnav-cart-height__large: $topnav-utility-item-height--large !default; // Match utility nav
$topnav-cart-width: $topnav-button-width !default; // Cart can be wider than the other buttons due to the counter
$topnav-cart-qty--size: 30px !default;
$topnav-cart-qty--size__medium: null !default;
$topnav-cart-qty--color: $topnav-color !default;
$topnav-cart-qty--bg: get-color(primary) !default;
$topnav-cart-qty--font-size: null !default;
$topnav-cart-qty--font-size__medium: null !default;
$topnav-cart-gutter-space: 0 !default;

// Dimensions for medium & up
$topnav-cart-icon-font-size__medium: 14px !default;
$topnav-cart-separator-padding__medium: 5px !default;
$topnav-cart-separator-padding__large: null !default;
$topnav-cart-icon-padding__medium: 0 $topnav-cart-separator-padding__medium !default;
$topnav-cart-padding__medium: 0 !default;
$topnav-cart-icon-padding__large: null !default;
$topnav-cart-margin__medium: 0 10px 0 0 !default;
$topnav-cart-margin__large: $topnav-cart-margin__medium !default;

// TopNav Cart Wrapper
@mixin topnav-cart {
	order: 1;
	position: absolute;
	right: calc(#{$topnav-button-width + $topnav-cart-gutter-space}); // position cart next to menubutton with gutter space between button and active state of menubutton

	.menubuttonleft & {
		right: $topnav-cart-gutter-space;
	}
}

// TopNav Cart Wrapper adjustments when MenuButton text is shown
@mixin topnav-cart-offset-menu-text {
	right: $topnav-menubutton-text-width;

	&:not(.has-products) {
		a {
			padding-right: 0;
		}
	}

	@include breakpoint(medium) {
		right: 0;
	}
}

// TopNav Cart Button
@mixin topnav-cart-link {
	@include topnav-button;

	height: $topnav-cart-height;
	overflow: hidden;
	position: relative;
	width: $topnav-cart-width;

	@include breakpoint(medium) {
		float: $global-right;
		height: $topnav-cart-height__medium;
		line-height: $topnav-cart-height__medium;
		margin: $topnav-cart-margin__medium;
		padding: $topnav-cart-padding__medium;
		width: auto;
	}

	@include breakpoint(large) {
		height: $topnav-cart-height__large;
		line-height: $topnav-cart-height__large;
		margin: $topnav-cart-margin__large;
	}

	@media only screen and (min-width: 640px) and (max-width: 1024px) {
		@media (-webkit-min-device-pixel-ratio: 2) {
			height: $topnav-cart-height__medium;
			line-height: $topnav-cart-height__tablet;
		}
    }
}

// Text label in the cart link
@mixin topnav-cart-label {
	font-size: $topnav-cart-label--font-size;

	@include breakpoint(large) {
		font-size: $topnav-cart-label--font-size__large;
	}

	&.hide-for-small-only {
		@include breakpoint(medium) {
			float: right;
			padding-right: $topnav-cart-separator-padding__medium;
		}

		@include breakpoint(large) {
			padding-right: $topnav-cart-separator-padding__large;
		}
	}
}

// Sets the quantity indicator inline
@mixin topnav-cart-qty-inline {
	@include vertical-align;
	float: $global-right;
	#{$global-right}: auto;
	margin-#{$global-right}: auto;
}

// 
// Colored circle for quantity badge
// But just a simple circle won't scale for multiple digits
//
@mixin topnav-cart-badge-size($size) {
	@if $size {
		border-radius: $size/2; // a calcuted rounding instead of 50% ensures pill shape instead of eliptical
		height: $size;
		line-height: $size;
		min-width: $size; // min-width instead of width lets the circle expand horizontally for multiple digits

	}
}

// 
// Colored circle for quantity badge
// But just a simple circle won't scale for multiple digits
//
@mixin topnav-cart-badge {
	@include topnav-cart-badge-size($topnav-cart-qty--size);
	display: inline-block;
	padding: 0 0.25em;
	text-align: center;

	@include breakpoint(medium) {
		@include topnav-cart-badge-size($topnav-cart-qty--size__medium);
	}
}

// Badge showing the quantity of items in the cart
@mixin topnav-cart-qty {
	@include topnav-cart-badge;
	@include vertical-align($position: absolute, $centering: 'bottom');

	background: $topnav-cart-qty--bg;
	color: $topnav-cart-qty--color;
	font-size: $topnav-cart-qty--font-size;
	#{$global-right}: 50%;
	margin-#{$global-right}: $topnav-cart-qty--size * -1;

	@include breakpoint(medium) {
		// The right mixin depends on the needed alignement
		// by providing both, it can easily be set in brand
		// overrides without touching _shared
		//
		// @include vertical-align();
		@include topnav-cart-qty-inline;
		font-size: $topnav-cart-qty--font-size__medium;
	}
}

@mixin topnav-cart-icon {
	@include breakpoint(medium) {
		display: block;
		float: $global-left;
		font-size: $topnav-cart-icon-font-size__medium;
		line-height: $topnav-cart-height__medium;
		padding: $topnav-cart-icon-padding__medium;
	}

	@include breakpoint(large) {
		padding: $topnav-cart-icon-padding__large;
	}
}

// This mixin is a handy way to get around
// SCSSLint selector depth warnings. Plus it makes
// things a bit more reusable and provides a nice
// way to enable/disable output of this entire module
@mixin topnav-utility-cart {
	.topnav {
		&-cart {
			.topnav-smallheader-cart & {
				@include topnav-cart;
			}

			a {
				@include topnav-cart-link;
			}

			&-label {
				@include topnav-cart-label;
			}

			&-qty {
				@include topnav-cart-qty;
			}

			&-icon {
				@include topnav-cart-icon;
			}
		}

		&-wishlist {
			&-qty {
				@include topnav-cart-qty;

				display: none;

				.has-products & {
					display: inline-block;
				}
			}
		}

		&-header {

			// adjust the position of the cart button when
			// 1) the menubutton text is enabled and
			// 2) when the menubutton is positioned on the right
			&.show-menubutton-text:not(.menubuttonleft) {
				.topnav-cart {
					@include topnav-cart-offset-menu-text;
				}
			}
		}
	}

}
