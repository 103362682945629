//
// Drives the display of the dropdown on small and up screens on the CMS-based
// top nav implementation.
//

@import 'global/topnav/dropdown'; // Get shared component

@mixin topnav-napa-dropdown-parent {
  @include breakpoint(medium) {
    .no-js & {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

@mixin topnav-napa-dropdown-button {
  // All buttons in dropdown should be small
  font-size: 1em;
}

@mixin topnav-napa-more-dropdown {
  @include breakpoint(medium) {
    border-top: 2px solid $black;
  }
}

@mixin topnav-dropdown-napapijri-eu {
  .topnav {

    &-dropdown {
      @include breakpoint(medium) {
        border-top: 1px solid $light-gray;
      }
    }

    &-mainnav {
      &-more {
        &-dropdown {
          @include topnav-napa-more-dropdown;
        }
      }
    }

    .l1 {

      &.has-children {
        @include topnav-napa-dropdown-parent;
      }
    }

    .button {
      @include topnav-napa-dropdown-button;
    }
  }
}
@mixin new-design-dropdown-medium {
  &.topnav-dropdown-imagegrid {
    .columns {
      padding-left: 0;
      padding-right: rem-calc(15);
    }
  }
}

@mixin new-design-accordion-l2-medium {
  &.topnav-accordion-item {
    > .topnav-accordion-item-link {
      font-family: get-font(AkkuratPro);
      font-weight: 700;
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      text-transform: capitalize;
      padding-bottom: 0 !important; // sass-lint:disable-line no-important
    }
  }
}

// L2 styles for medium
@mixin accordion-l2-medium {
  &.topnav-accordion-item {
    > .topnav-accordion-item-link {
      text-transform: uppercase;
    }
  }
}
@mixin new-design-accordion-l1-medium {
  &.topnav-accordion-item {

    > a {
      text-transform: capitalize;
      line-height: rem-calc(18);
    }

    .topnav-dropdown-list {
      .topnav-accordion-shopall {
        display: block;
      }
      .topnav-accordion-item-link {
        font-family: $about-us-mega-menu-dropdown-font-family;
      }
    }

    .topnav-dropdown-imagegrid {
      .topnav-accordion-shopall {
        display: block;
      }
      &-caption {
        line-height: rem-calc(17);
        font-family: $about-us-mega-menu-dropdown-font-family;
        text-align: left;
        color: $white;
        position: relative;
        bottom: rem-calc(40);
        padding-left: rem-calc(15);
        transition: 0.3s;
        &-desc {
          padding: 0;
          margin: 0;
          line-height: 0.7;
          &-cta {
            font-weight: 300;
            font-size: rem-calc(12);
            line-height: 3.7;
          }
        }
      }
      &-link:hover{
        text-decoration: none;
        .topnav-dropdown-imagegrid-caption {
          bottom: rem-calc(60);
          transition: 0.3s;
          &-desc-cta{
            line-height: 2.7;
          }
        }
      }
      &-image {
        width: 100%;
        height: rem-calc(256);
        background: linear-gradient(180deg, $white  0%, get-color(night-rider) 82.29%);
        mix-blend-mode: multiply;
        picture {
          mix-blend-mode: multiply;
        }
        &-height {
          height: rem-calc(256);
        }
      }
    }

    .topnav-accordion-shopall {
      display:none;
      margin-bottom: 0;

      &-link {
        font-size: rem-calc(16);
        text-transform: capitalize;
        line-height: rem-calc(20);
      }
    }
  }
}
.napa-top-nav-new-design {
  .topnav-mainnav-more-dropdown-close{
    display: none;
  }
  .topnav-dropdown {
    @include breakpoint(medium) {
      @include new-design-dropdown-medium;
    }
    button.topnav-dropdown-close {
      display: none;
    }
    &-l3 {
      .topnav-accordion-item-link {
        color: $dark-gray;
      }
    }
  }

  .topnav-accordion {

    .l1 {
      @include breakpoint(medium) {
        @include new-design-accordion-l1-medium;
      }

      .topnav-dropdown-wrapper {
        > .topnav-accordion-shopall {
          a {
            font-family: $about-us-mega-menu-dropdown-font-family;
            font-size: rem-calc(16);
            font-weight: 700;
            line-height: rem-calc(24);
            padding-left: rem-calc(24);
            padding-top: 0;

            @include breakpoint(medium) {
              font-weight: 500;
              line-height: rem-calc(20);
              padding-left: 0;
              padding-top: rem-calc(5);
            }
          }
        }
        @include breakpoint(medium) {
          .medium-2 {
            width: 20%;
          }
        }
        .image-bottom {
          height: auto;
          @include breakpoint(medium) {
            height: rem-calc(256);
            margin-top: rem-calc(16);
          }
        }
        .image-row-top  {
          height: auto;
          @include breakpoint(medium) {
            height: rem-calc(256);
          }
        }
        .media picture img {
          height: rem-calc(256);
        }
      }
    }

    .topnav-dropdown-list {
      .l2 {
        @include breakpoint(medium) {
          @include new-design-accordion-l2-medium;
        }
      }
    }

    .l2 {
      @include breakpoint(medium) {
        @include new-design-accordion-l2-medium;
      }
    }

  }
  .category-icon{
    max-width: rem-calc(32);
    max-height: rem-calc(20);
    float: right;
    margin-left: rem-calc(12);
    margin-top: rem-calc(-3);
  }
  .imagegrid-5 {
    width: 100%;
    @include breakpoint(medium) {
      width: 20%;
    }
  }
}