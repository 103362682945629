//
// Drives the display of the promo bar on the CMS-based
// top nav implementation.
//

@import 'global/z-indexes';
@import 'cms/widgets/carousel-controls';

$topnav-promobar--height: 28px !default;
$topnav-promobar--bgColor: get-color(primary) !default;
$topnav-promobar--borderBottom: null !default;
$topnav-promobar--borderTop: null !default;
$topnav-promobar--textColor: $white !default;
$topnav-promobar--textTransform: null !default;
$topnav-promobar--fontFamily: null !default;
$topnav-promobar--fontWeight: null !default;
$topnav-promobar--linkColor: inherit !default;
$topnav-promobar--linkHoverColor: inherit !default;
$topnav-promobar--linkDecoration: null !default;
$topnav-promobar--linkHoverDecoration: null !default;
$topnav-promobar--fontSize: rem-calc(11px) !default;
$topnav-promobar--medium-fontSize: $topnav-promobar--fontSize !default;
$topnav-promobar--medium-height: $topnav-promobar--height !default;
$topnav-promobar--large-fontSize: $topnav-promobar--medium-fontSize !default;
$topnav-promobar--large-height: $topnav-promobar--medium-height !default;
$topnav-promobar--lineHeight: 1.1em !default;
$topnav-promobar--medium-lineHeight: null !default;
$topnav-promobar--large-lineHeight: null !default;
$topnav-promobar-message--maxHeight: $topnav-promobar--lineHeight * 2 !default;
$topnav-promobar-message--medium-maxHeight: null !default;
$topnav-promobar-message--large-maxHeight: null !default;
$topnav-promobar-position: relative !default;

//override font size for carousels that require a different size
$topnav-promobar-carousel--fontSize: null !default;

// Use the global shared overlay arrow styles by default
$topnav-promobar-overlay-arrow-size: $carousel-overlay-arrow-size !default;
$topnav-promobar-overlay-arrow-color: $carousel-overlay-arrow-color !default;
$topnav-promobar-overlay-arrow-background: $topnav-promobar--bgColor !default;
$topnav-promobar-overlay-arrow-color-hover: $carousel-overlay-arrow-color-hover !default;
$topnav-promobar-overlay-arrow-background-hover: $carousel-overlay-arrow-background-hover !default;
$topnav-promobar-overlay-arrow-width: $carousel-overlay-arrow-width !default;
$topnav-promobar-overlay-arrow-height: 100% !default;
$topnav-promobar-overlay-arrow-opacity: $carousel-overlay-arrow-opacity !default;
$topnav-promobar-overlay-arrow-opacity-hover: $carousel-overlay-arrow-opacity-hover !default;
$topnav-promobar-overlay-arrow-transition-delay: 0.2s !default;
$topnav-promobar-overlay-content-padding: $topnav-promobar-overlay-arrow-width !default;

//override order of topnav content
$topnav-promobar-container-order: 5 !default;


@mixin topnav-promobar-message-heights($maxHeight: null, $line-height: null) {
	@if($maxHeight) {
		max-height: $maxHeight;
	} @else {
		@if($line-height) {
			max-height: $line-height * 2;
		}
	}

	p {
		line-height: $line-height;
	}
}

@mixin topnav-promobar-message {
	@include vertical-align;
	max-height: $topnav-promobar-message--maxHeight;
	overflow: hidden;

	p {
		font-family: $topnav-promobar--fontFamily;
		font-size: $topnav-promobar--fontSize;
		font-weight: $topnav-promobar--fontWeight;
		line-height: $topnav-promobar--lineHeight;
		margin: 0;
		overflow: hidden;
		overflow-wrap: break-word;
		position: relative;
		text-overflow: ellipsis;
		text-transform: $topnav-promobar--textTransform;

		&:empty,
		&:nth-child(n+3) {
			display: none; //hide all empty paragraphs and all paragraphs after 2nd because promobar can only support 2 lines
		}

		a,
		a:visited,
		a:active {
			color: $topnav-promobar--linkColor;
			display: inline-block;
			text-decoration: $topnav-promobar--linkDecoration;

			&:hover {
				color: $topnav-promobar--linkHoverColor;
				text-decoration: $topnav-promobar--linkHoverDecoration;
			}
		}

		// Override default link color when custom color is set in Authoring RTE
		span {
			a,
			a:visited,
			a:active,
			a:hover {
				color: inherit;
			}
		}
	}

	@include breakpoint(medium) {
		p {
			font-size: $topnav-promobar--medium-fontSize;
		}

		@include topnav-promobar-message-heights($topnav-promobar-message--medium-maxHeight, $topnav-promobar--medium-lineHeight);
	}

	@include breakpoint(large) {
		p {
			font-size: $topnav-promobar--large-fontSize;
		}

		@include topnav-promobar-message-heights($topnav-promobar-message--large-maxHeight, $topnav-promobar--large-lineHeight);
	}
}

@mixin topnav-promobar-carousel {
	height: 100%;

	.carousel {
		height: 100%;
	}

	.slide {
		height: 100%;
		width: 100%;

		p {
			padding-left: $topnav-promobar-overlay-content-padding;
			padding-right: $topnav-promobar-overlay-content-padding;
			font-size: $topnav-promobar-carousel--fontSize;
			font-weight: $topnav-promobar--fontWeight;
		}
	}

	.cycle-prev,
	.cycle-next {
		margin-top: 0;
		top: 0;

		&::before {
			line-height: $topnav-promobar--height;

			@include breakpoint(medium) {
				line-height: $topnav-promobar--medium-height;
			}

			@include breakpoint(large) {
				line-height: $topnav-promobar--large-height;
			}
		}
	}

	&.show-controls-on-focus {
		@include carousel-controls-on-hover(
			$background-hover: $topnav-promobar-overlay-arrow-background-hover,
			$color-hover: $topnav-promobar-overlay-arrow-color-hover,
			$opacity: 0,
			$opacity-hover: $topnav-promobar-overlay-arrow-opacity-hover,
			$override-visibility-class: false
		);
	}
}

@mixin topnav-promobar-container {
	clear: both;
	flex: 100%;
	order: 5;
	position: relative;
	width: 100%;

	@include breakpoint(large) {
		order: $topnav-promobar-container-order;
	}
}

@mixin topnav-promobar-innercontainer {
	position: relative;
	width: 100%;
}

@mixin topnav-promobar {
	.topnav {
		&-promobar-innercontainer {
			@include topnav-promobar-innercontainer;
		}

		&-promobar-container {
			@include topnav-promobar-container;

			.topnav-promobar {
				background-color: $topnav-promobar--bgColor;
				border-bottom: $topnav-promobar--borderBottom;
				border-top: $topnav-promobar--borderTop;
				color: $topnav-promobar--textColor;
				display: block;
				height: $topnav-promobar--height;
				overflow: hidden;
				position: $topnav-promobar-position;

				// when the global promobar link is enabled, prevent nested links from being clicked
				&.topnav-promobar-global-link {
					a {
						pointer-events: none;
					}
				}

				@include breakpoint(medium) {
					@include z-index(topnav);
					height: $topnav-promobar--medium-height;
					transition: top $topnav-dropdown-transition-duration ease-out;

					.no-js & {
						z-index: 1;
					}
				}

				@include breakpoint(large) {
					height: $topnav-promobar--large-height;
				}

				&-message {
					@include topnav-promobar-message;
				}

				.slide.cycle-slide {
					display: none !important;
				  }
				  
				.slide.cycle-slide-active {
					display: block !important;
				  }
			}
		}

		&-promobar-carousel {

			@include carousel-overlay-arrows(
			    $topnav-promobar-overlay-arrow-width,
			    $topnav-promobar-overlay-arrow-height,
			    $topnav-promobar-overlay-arrow-size,
			    $topnav-promobar-overlay-arrow-color,
			    $topnav-promobar-overlay-arrow-background,
			    $topnav-promobar-overlay-arrow-color-hover,
			    $topnav-promobar-overlay-arrow-background-hover,
			    $topnav-promobar-overlay-arrow-opacity,
				$topnav-promobar-overlay-arrow-opacity-hover,
				$topnav-promobar-overlay-arrow-transition-delay
			);

			@include topnav-promobar-carousel;
		}
	}
}
