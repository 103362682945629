//
// Drives the flag selector top nav implementation.
//
@import 'global/topnav/flag';

@mixin topnav-utility-item-flag-napapijri-eu {

  .topnav-utility-item--flag-image-wrapper {
    @include breakpoint(medium) {
      display: inline-block;
      margin-right: 0;
    }

    .topnav-utility-item--flag-image {
      align-self: center;
    }
  }

  .topnav-utility-item > .topnav-utility-item--flag-link {
    @include breakpoint(medium) {
      display: block;
    }
  }
}
