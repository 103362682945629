//
// Drives the display of the imagegrid dropdown on medium and up screens on the CMS-based
// top nav implementation.
//

$topnav-dropdown-imagegrid-img-margin-bottom__med: 14px !default;
$topnav-dropdown-imagegrid-caption-margin-bottom__med: 0 !default;
$topnav-dropdown-imagegrid-caption-font-size__med: 14px !default;
$topnav-dropdown-imagegrid-caption-line-height__med: 15px !default;
$topnav-dropdown-imagegrid-caption-color: $topnav-dropdown-item-color !default;
$topnav-dropdown-imagegrid-caption-color-hover: $topnav-dropdown-item-color-active !default;
$topnav-dropdown-imagegrid-link-margin-bottom__med: 24px !default;


@mixin topnav-dropdown-imagegrid {
  // mobile styles are inherited from L2

  .topnav {
    &-dropdown {
      &-imagegrid {
        @include breakpoint(medium) {
          &-image {
            margin-bottom: $topnav-dropdown-imagegrid-img-margin-bottom__med;
          }

          &-caption {
            color: $topnav-dropdown-imagegrid-caption-color;
            display: block;
            font-size: $topnav-dropdown-imagegrid-caption-font-size__med;
            line-height: $topnav-dropdown-imagegrid-caption-line-height__med;
            margin-bottom: $topnav-dropdown-imagegrid-caption-margin-bottom__med;
            text-align: center;
            text-transform: none;
          }

          &-link {
            display: block;
            height: 100%;
            margin-bottom: $topnav-dropdown-imagegrid-link-margin-bottom__med;

            &:active,
            &:focus,
            &:hover {
              .topnav-dropdown-imagegrid-caption {
                color: $topnav-dropdown-imagegrid-caption-color-hover;
              }
            }
          }

          &-item {
            & > .topnav-dropdown-imagegrid-link {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

