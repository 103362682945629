//
// Drives the display of the search button on the CMS-based
// top nav implementation.
//

@import 'global/topnav/search'; // Get shared component

@mixin topnav-napa-search-input {
  // Must have different placeholder color on desktop and mobile
  @include placeholder($light-gray);
  border: 0;
  color: $topnav-search-button-text-placeholder-color;

  @include breakpoint(medium) {
    border: 1px solid $medium-gray;

    &:focus {
      border: none;
    }
  }

  @include breakpoint(medium) {
    @include placeholder($topnav-search-button-text-placeholder-color);
  }
}

@mixin topnav-napa-search-button {
  @include secondary-button;
  border: 0;
  font-size: $topnav-search-button-text-font-size;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: $black;
  }

  @include breakpoint(medium) {
    font-size: 14px;
  }
}

@mixin topnav-search-container-napapijri-eu {
  .topnav {
    & &-search-container {
     
        border: 0;
     

      .topnav-search-input {
        @include topnav-napa-search-input;
        padding-bottom: 7px;
        padding-right: 34px;
        height: rem-calc(38);

        &::placeholder {
          color: $black;
          font-size: rem-calc(12);
          font-style: normal;
          font-weight: 300;
          line-height: rem-calc(18);
        }

      }

      .topnav-search-button {
        @include topnav-napa-search-button;

        &-container {

          @include breakpoint(medium) {
            background-color: $white;
            height: 32px;
            margin: 1px;
            padding: 0;
            position: absolute;
            right: 20px;
          }
        }
      }

            &::after {
              content: "";
              position: absolute;
              height: 100vh;
              width: fill-available;
              background: $black;
              margin-top: rem-calc(8);
              margin-left: rem-calc(-10);
              opacity: 0.6;

              @include breakpoint(medium) {
                display: none;
              }
            }
    }
  }
}
.napa-top-nav-new-design {
  .topnav-search-container {
    width: 100%;
    z-index: z-index(topnav) +1;
    @include breakpoint(medium){
      width: rem-calc(230) !important;  // sass-lint:disable-line no-important
      margin-right: rem-calc(60) !important;  // sass-lint:disable-line no-important
      display: flex !important;
      align-items: center;
      padding: $new-topnav-search-container-padding__medium;
      justify-content: flex-end;
    }

    @include breakpoint(large) {
      order: 4;
      margin-right: rem-calc(82);
    }

    .search {
      display: block;
      border-bottom: rem-calc(1) solid $black;
      
      @include breakpoint(medium) {
        display: flex;
        align-items: center;
        height: rem-calc(40);
        padding: rem-calc(6) 0 rem-calc(6) rem-calc(4);
      }
    }

    .topnav-search-input {
      .transperant-topnav & {
        @include breakpoint(medium) {
          padding-bottom: rem-calc(14);
        }
      }

      @include breakpoint(medium) {
        background-color: $white;
      }
    }

    .topnav-search {
      &-button {
        &-container {
          margin-top: rem-calc(6);
          order: 2;
          @include breakpoint(medium) {
            order: 1;
            margin-top: rem-calc(0);

            .transperant-topnav & {
              top: rem-calc(2);
            }
          }
          .icon-search {
            &:before {
              font-size: rem-calc(28);
            }
          }
          .icon-slim-search {
            &:before {
              font-size: rem-calc(24);

              @include breakpoint(medium) {
                font-size: rem-calc(28);
              }
            }
          }
        }
      }

      &-input {
        @include breakpoint(medium) {
          border: none !important;  // sass-lint:disable-line no-important
          &:focus {
            border: none !important;  // sass-lint:disable-line no-important
            &::placeholder {
              color: transparent;
            }
          }
        }
        &-container {
          order: 4;

          @include breakpoint(medium) {
            order: 2;
          }

        }

        @include breakpoint(medium) {
          font-size: rem-calc(12);
          height: rem-calc(24);
          position: relative;
        }

      }
    }
    .topnav-clear-input {
      background: transparent;
      border: 0;
      color: $black;
      display: none;
      font-size: rem-calc(18);
      margin:	0;
      padding: 0;
      padding-left: rem-calc(5);
      position: absolute;
      right: rem-calc(25);
      top: rem-calc(27);
      transform: translateY(-50%);

      @include breakpoint(medium) {
        top: rem-calc(38);
        font-size: rem-calc(24);

      }
    }
    .topnav-search-form {
			&.focus {
				outline: none !important; // sass-lint:disable-line no-important
			}
		}
  }
  .topnav-search-input {
    color: $black;
    font-weight: 300;
    line-height: rem-calc(18) !important; // sass-lint:disable-line no-important
    padding-bottom: rem-calc(11) !important; // sass-lint:disable-line no-important
    font-size: rem-calc(12);
    border: none !important;  // sass-lint:disable-line no-important
  }
  .topnav-search-button {
    width: rem-calc(32) !important; // sass-lint:disable-line no-important;
    height: rem-calc(32) !important; // sass-lint:disable-line no-important;
  }
  .topnav-search-button-container {

    @include breakpoint(medium) {
      position: relative !important;  // sass-lint:disable-line no-important
      right: 0 !important;  // sass-lint:disable-line no-important
    }
  }
}

.autosuggest-overlay{
  &.autosuggest-active {
    .napa-top-nav-new-design {
      .topnav-search-container {
        .topnav-clear-input {
          display: none !important; // sass-lint:disable-line no-important
        }
      }
    } 
  }
}