@import 'vfdp/components/visibility';
//
// Drives the set of small icons shown before
// opening menu on small screens
//

$topnav-smallheader-container-order: 2 !default;

@mixin topnav-smallheader-list {
	display: inline; // Foundation .inline-list is actually missing display:inline on the <ul>
}

@mixin topnav-smallheader-button {
	@include topnav-button;
}

@mixin topnav-smallheader-container {
	display: flex;
	flex-grow: 1;
	flex-wrap: nowrap;
	justify-content: space-between;
	order: 1;

	@include breakpoint(medium) {
		display: inline-block;
		flex-grow: 0;
		order: 2;
	}

	@include breakpoint(large) {
		order: $topnav-smallheader-container-order;
	}
}

@mixin topnav-smallheader {
	.topnav {
		&-smallheader {
			float: $global-right;
			height: $topnav-button-height;
			order: 2;
			overflow: hidden;

			ul {
				@include topnav-smallheader-list;
			}

			.topnav-smallheader-item {
				margin-left: 0; // remove margin added by Foundation inline-list

				a {
					@include topnav-smallheader-button;
				}
			}

			&-container {
				@include topnav-smallheader-container;
			}
		}
	}
}
