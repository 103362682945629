@import 'vfdp/components/visibility';
//
// Drives the utility top
// nav implementation.
//

// Dimensions for small screens
$topnav-utility-item--font-size: $topnav-accordion--font-size !default;
$topnav-utility-item--height: $topnav-accordion-item--height !default;
$topnav-utility-item--line-height: $topnav-utility-item--height !default;
$topnav-utility-item--padding: $topnav-accordion-item--padding-base !default;
$topnav-utility-item--indent: $topnav-accordion-item--padding-base - $topnav-utility-item--padding !default; // Indent + Padding should equal the main L1 padding for alignment
$topnav-utility-item--icon-width: 20px !default;
$topnav-utility-item--icon-spacer: 3px !default;
$topnav-utility--padding: null !default;
$topnav-utility--float__medium: $global-right !default;
$topnav-utility-item-label-display-medium: none !default;
$topnav-utility-item-float-medium: $global-left !default;

// Dimensions for medium & up
$topnav-utility-item--font-size--medium: null !default;
$topnav-utility-item-padding--medium: 0 !default;
$topnav-utility-item-height--medium: 43px !default;
$topnav-utility-item-height--large: null !default;
$topnav-utility-item-width--medium: 30px !default;
$topnav-utility-item-icon-font-size: $topnav-utility-item--font-size !default;
$topnav-utility-item-icon-font-size--medium: 1em !default;
$topnav-utility-item-icon-min-width: null !default;
$topnav-utility-item-icon-min-width--medium: null !default;
$topnav-utility-item-icon-margin-left: null !default;
$topnav-utility-item-icon-margin-left--medium: null !default;
$topnav-utility-item-icon-padding: 0 rem-calc(8px) !default;
$topnav-utility-item-icon-padding--large: 0 !default;
$topnav-utility--padding__medium: 2px 0 0 0 !default;

// Dimensions for large & up
$topnav-utility-item-font-size--large: null !default;
$topnav-utility-item-margin--large: 0 ($topnav-utility-item--padding/2) 0 0 !default;
$topnav-utility-item-separation-padding--large: 18px !default;
$topnav-utility--padding__large: null !default;

// Wishlist Notification
$topnav-wishlist-notify-background: $white !default;
$topnav-wishlist-notify-border: 1px solid $light-gray !default;
$topnav-wishlist-notify-border-top: 3px solid get-color(primary) !default;
$topnav-wishlist-notify-padding: 1.5rem !default;
$topnav-wishlist-notify-width: 300px !default;
$topnav-wishlist-notify-close-size: rem-calc(14px) !default;
$topnav-wishlist-notify-success-color: get-color(primary) !default;
$topnav-wishlist-notify-success-font-family: null !default;
$topnav-wishlist-notify-success-font-size: null !default;
$topnav-wishlist-notify-success-font-weight: bold !default;
$topnav-wishlist-notify-success-icon: null !default;
$topnav-wishlist-notify-success-transform: null !default;
$topnav-wishlist-notify-message-color: null !default;
$topnav-wishlist-notify-message-font-family: null !default;
$topnav-wishlist-notify-message-font-size: null !default;
$topnav-wishlist-notify-message-font-weight: null !default;
$topnav-wishlist-notify-message-spacing: null !default;
$topnav-utility-cart-container-order: 1 !default;

// Icons in utility nav
//
// Line them up consistently even though they are different sizes
// by centering them in consistent-width containers
//
@mixin topnav-utility-item-icon {
	display: inline-block;
	float: $global-left;
	font-size: $topnav-utility-item-icon-font-size;
	line-height: inherit;
	margin-left: $topnav-utility-item-icon-margin-left;
	margin-right: $topnav-utility-item--icon-spacer;
	min-width: $topnav-utility-item-icon-min-width;
	padding: $topnav-utility-item-icon-padding;
	text-align: center;

	@include breakpoint(medium) {
		font-size: $topnav-utility-item-icon-font-size--medium;
		line-height: $topnav-utility-item-height--medium;
		margin-left: $topnav-utility-item-icon-margin-left--medium;
		margin-right: 0;
		min-width: $topnav-utility-item-icon-min-width--medium;
	}

	@include breakpoint(large) {
		line-height: $topnav-utility-item-height--large;
		margin: $topnav-utility-item-margin--large;
		padding: $topnav-utility-item-icon-padding--large;
		width: auto;
	}
}

//
// Utility nav list items
//
@mixin topnav-utility-item {
	@include topnav-accordion-item-borders($excludeFirst: false);
	@include topnav-accordion-item-size($level: 0, $indent: $topnav-utility-item--indent, $padding: $topnav-utility-item--padding, $height: $topnav-utility-item--height, $link: "&-link, &--flag-link");
	@include topnav-accordion-item-colors;

	display: block;
	font-size: $topnav-utility-item--font-size;
	line-height: $topnav-utility-item--line-height;
	position: relative;

	// Line up the utility nav icons consistently
	&-icon {
		@include topnav-utility-item-icon
	}

	&-link,
	&--flag-link {
		@include topnav-accordion-indent($indent: $topnav-utility-item--indent, $padding: $topnav-utility-item--padding);
	}

	@include breakpoint(medium) {
		@include topnav-accordion-item-size($level: 0, $indent: 0, $padding: $topnav-utility-item-padding--medium, $height: $topnav-utility-item-height--medium, $link: "&-link, &--flag-link");
		float: $global-left;
		font-size: $topnav-utility-item--font-size--medium;
		height: $topnav-utility-item-height--medium;
		width: auto;

		&-link,
		&--flag-link {
			height: 100%;
		}

		&-link {
			line-height: $topnav-utility-item-height--medium;
			text-align: center;
		}

		&-link,
		&--flag-link,
		&.topnav-cart a {
			&:hover,
			&:focus {
				:nth-last-child(1):not(.qty) {
					text-decoration: underline;
				}
			}
		}

		&-label {
			display: none;
		}
		@media only screen and (min-width: 640px) and (max-width: 1024px) {
			@media (-webkit-min-device-pixel-ratio: 2) {
				float: $topnav-utility-item-float-medium;
				&-label {
					display: $topnav-utility-item-label-display-medium;
				}
			}
		}
	}

	@include breakpoint(large) {
		font-size: $topnav-utility-item-font-size--large;
		height: $topnav-utility-item-height--large;
		padding-left: $topnav-utility-item-separation-padding--large;

		& &-link {
			display: inline-block;
			line-height: initial;
			text-align: left;
		}

		&-label {
			display: inline-block;
			line-height: $topnav-utility-item-height--large;
			vertical-align: bottom;
		}
	}
}

@mixin topnav-wishlist-notification {
	background: $topnav-wishlist-notify-background;
	border: $topnav-wishlist-notify-border;
	border-top: $topnav-wishlist-notify-border-top;
	opacity: 0;
	padding: $topnav-wishlist-notify-padding;
	position: absolute;
	right: 0;
	text-align: center;
	top: $topnav-button-height;
	transition: opacity 0.6s;
	width: 100%;
	z-index: z-index(minicart);

	&.fade-in {
		opacity: 1;
	}

	.close-button {
		font-size: $topnav-wishlist-notify-close-size;
	}

	.success-label {
		color: $topnav-wishlist-notify-success-color;
		font-family: $topnav-wishlist-notify-success-font-family;
		font-size: $topnav-wishlist-notify-success-font-size;
		font-weight: $topnav-wishlist-notify-success-font-weight;
		text-transform: $topnav-wishlist-notify-success-transform;

		// override icon with option not available in AEM
		@if $topnav-wishlist-notify-success-icon {
			[class^="icon-"],
			[class*=" icon-"] {
				&:before {
					@include icomoonFont($topnav-wishlist-notify-success-icon, false);
				}
			}
		}
	}

	.wishlist-message {
		padding: 1rem 0;

		// these optional styles allow for consistently with the text-editor output
		span,
		p {
			color: $topnav-wishlist-notify-message-color;
			font-family: $topnav-wishlist-notify-message-font-family;
			font-size: $topnav-wishlist-notify-message-font-size;
			font-weight: $topnav-wishlist-notify-message-font-weight;
			letter-spacing: $topnav-wishlist-notify-message-spacing;
			margin-bottom: 0;
		}

		a {
			text-decoration: underline; // restore underline removed from topnav links
		}
	}

	.button {
		margin: 0;
	}

	@include breakpoint(medium) {
		top: $topnav-utility-item-height--medium;
		width: $topnav-wishlist-notify-width;
	}

	@include breakpoint(large) {
		top: $topnav-utility-item-height--large;
		transform: translateX(50%);
	}
}

// TopNav Utility
// Styles the utility links
@mixin topnav-utility {
    .topnav {
        &-utility {
						padding: $topnav-utility--padding;

            &-cart-container {
                @include topnav-utility-cart-container;
            }

            &-item {
                @include topnav-utility-item;
            }

            @include breakpoint(medium) {
                float: $global-right;
                padding: $topnav-utility--padding__medium;

				@media only screen and (min-width: 640px) and (max-width: 1024px) {
					@media (-webkit-min-device-pixel-ratio: 2) {
						float: $topnav-utility--float__medium;
					}
				}
				
                ul {
                    // Show as inline list on medium and up
                    @include inline-list;
                    position: absolute;
                    right: 0;
                    top: 8px;

                    & > li {
                        margin-left: 0;
                        padding: 0 0.5em;
                        width: auto;
                    }
                }
            }

            @include breakpoint(large) {
				padding: $topnav-utility--padding__large;

				// hide text when utility nav is too large for one line
				&.hide-text {
					.topnav-utility-item-label {
						display: none;
					}
				}
			}
		}

		&-wishlist-notification {
			@include topnav-wishlist-notification;
		}
    }
}

@mixin topnav-utility-cart-container {
	@include breakpoint(medium) {
		flex: 1 100%;
		flex-direction: row-reverse;
		flex-grow: 1;
		order: 1;
	}

	@include breakpoint(medium) {
		order: $topnav-utility-cart-container-order;
	}
}
