//
// Adds CSS transitions to animate the stickynav header when it becomes minimized
//

// base transition settings
$topnav-animate-duration: 0.6s !default;
$topnav-animate-transition: cubic-bezier(0.22, 0.61, 0.36, 1.00) !default;

// header transitions
$topnav-animate-header-delay: null !default;
$topnav-animate-header-duration: $topnav-animate-duration !default;
$topnav-animate-header-transition: $topnav-animate-transition !default;

// logo transitions
$topnav-animate-logo-delay: null !default;
$topnav-animate-logo-duration: $topnav-animate-duration !default;
$topnav-animate-logo-transition: $topnav-animate-transition !default;

// mainnav transitions
$topnav-animate-mainnav-delay: null !default;
$topnav-animate-mainnav-duration: $topnav-animate-duration/2 !default;
$topnav-animate-mainnav-transition: $topnav-animate-transition !default;

// promobar transitions
$topnav-animate-promobar-delay: null !default;
$topnav-animate-promobar-duration: $topnav-animate-duration/2 !default;
$topnav-animate-promobar-transition: $topnav-animate-transition !default;

// search transitions
$topnav-animate-search-delay: null !default;
$topnav-animate-search-duration: $topnav-animate-duration/2 !default;
$topnav-animate-search-transition: $topnav-animate-transition !default;

// utility item transitions
$topnav-animate-utility-item-delay: null !default;
$topnav-animate-utility-item-duration: $topnav-animate-duration !default;
$topnav-animate-utility-item-max-width: 120px !default; // default max-width needs to be wider than any utility nav item label. If its too wide the animation will look delayed
$topnav-animate-utility-item-transition: $topnav-animate-transition !default;

// utility link transitions
$topnav-animate-utility-link-delay: null !default;
$topnav-animate-utility-link-duration: $topnav-animate-duration !default;
$topnav-animate-utility-link-transition: $topnav-animate-transition !default;

// There is no animation for the height on small screens, start with medium
// This should NOT account for the height of the promobar
$topnav-animate-mainnav-height-medium-minimized: null !default;
$topnav-animate-mainnav-height-medium-maximized: null !default;
$topnav-animate-mainnav-height-large-minimized: null !default;
$topnav-animate-mainnav-height-large-maximized: null !default;

@mixin set-topnav-transitions {

  // setup transition properties for medium screens (transitions won't be applied to small screens)
  @include breakpoint(medium) {

    // .topnav-header
    height: $topnav-animate-mainnav-height-medium-maximized;
    transform: translate3d(0, 0, 0); // translate3D for css hardware acceleration
    transition-delay: $topnav-animate-header-delay;
    transition-duration: $topnav-animate-header-duration;
    transition-property: height;
    transition-timing-function: $topnav-animate-header-transition;


    .topnav {
      // .topnav-logo
      &-logo {
        transition-delay: $topnav-animate-logo-delay;
        transition-duration: $topnav-animate-logo-duration;
        transition-property:  width, height, margin-left;
        transition-timing-function: $topnav-animate-logo-transition;
      }

      // .topnav-mainnav
      &-mainnav {
        opacity: 1;
        transition-delay: $topnav-animate-mainnav-delay;
        transition-duration: $topnav-animate-mainnav-duration;
        transition-property: opacity;
        transition-timing-function: $topnav-animate-mainnav-transition;

        &.fade-out {
          opacity: 0;
          display: none;
        }
      }

      // .topnav-promobar
      &-promobar {
        opacity: 1;
        top: 0;
        transform: translate3d(0, 0, 0); // translate3D for css hardware acceleration
        transition-delay: $topnav-animate-promobar-delay, $topnav-animate-promobar-delay, 0;
        transition-duration: $topnav-animate-promobar-duration, $topnav-animate-promobar-duration, $topnav-dropdown-transition-duration;
        transition-property: opacity, transform, top; // top transitions are also set when dropdowns are opened
        transition-timing-function: $topnav-animate-promobar-transition, ease-out, ease-out;

        // .topnav-promobar-container: use the promobar container as a mask for hiding the promobar
        &-container.mask {
          overflow: hidden;
        }

        &.slide-up {
          transform: translate3d(0, -$topnav-promobar--height, 0);
        }
      }

      // .topnav-search-container
      &-search-container {
        opacity: 1;
        transition-delay: $topnav-animate-search-delay;
        transition-duration: $topnav-animate-search-duration;
        transition-property: opacity;
        transition-timing-function: $topnav-animate-search-transition;

        &.fade-out {
          opacity: 0;
        }
      }

      // .topnav-utility
      &-utility {
        &-item {
          white-space: nowrap;
        }
      }
    }
  }

  // setup transition properties only applicable to large screens
  @include breakpoint(large) {
    height: $topnav-animate-mainnav-height-large-maximized;

    .topnav {

      // .topnav-promobar
      &-promobar {
        &.slide-up {
          transform: translate3d(0, -$topnav-promobar--large-height, 0);
        }
      }

      // .topnav-utility
      &-utility {
        &-item {
          transition-delay: $topnav-animate-utility-item-delay;
          transition-duration: $topnav-animate-utility-item-duration;
          transition-property: padding;
          transition-timing-function: $topnav-animate-utility-item-transition;

          &-label {
            max-width: $topnav-animate-utility-item-max-width;
            overflow: hidden;
            transition-delay: $topnav-animate-utility-link-delay;
            transition-duration: $topnav-animate-utility-link-duration;
            transition-property: max-width;
            transition-timing-function: $topnav-animate-utility-link-transition;
          }
        }
      }
    }
  }
}


// set properties to be animated for minified sticky header
@mixin animate-topnav-sticky {
  @include breakpoint(medium) {
    height: $topnav-animate-mainnav-height-medium-minimized;
  }

  @include breakpoint(large) {
    height: $topnav-animate-mainnav-height-large-minimized;

    .topnav {

      // .topnav-utility
      &-utility {
        &-item {
          &--flag {
            max-width: none;
          }

          &-link {
            width: auto;
          }

          &-label {
            display: inline-block;
            max-width: 0;
          }
        }
      }
    }
  }
}

@mixin topnav-animate {
  .topnav {

    .sticky {

      // setup the default properties to enable css transitions
      &.sticky-animate {
        @include set-topnav-transitions;

        // apply changes to the properties to be animated
        &.sticky-minimized {
          @include animate-topnav-sticky;
        }
      }
    }
  }
}
